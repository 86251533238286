export enum PlanTypes {
	CLOUD_STORE = "cloud_store",
	MICRO_STORE = "micro_store",
	STANDARD_STORE = "standard_store",
}

export enum TranslatedPlanType {
	CLOUD_STORE = "雲端店",
	MICRO_STORE = "微型店",
	STANDARD_STORE = "標準店",
}
