import { Modal } from "antd";
import React from "react";
import "./loader.scss";

export const Loading: React.FunctionComponent = () => {
	return (
		<Modal className="loading_container" open={true} footer={[]} closable={false}>
			<div className="loader_box">
				<img className="graph_loader" src="/images/graph-loader.gif" />
			</div>
		</Modal>
	);
};
