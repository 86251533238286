import React from "react";
import { useAppSelector } from "../../modal/hooks";

export const MealPeriod: React.FunctionComponent = () => {
	const shopRating = useAppSelector((store) => store.analyzeLocation.shopRating);

	return (
		<div className="platform_content_box d-flex flex-column overflow-hidden">
			<div className="content_box_sub_title">评级</div>
			<div className="flex_1_1_10">
				{shopRating.length ? (
					<div className="pt-4">
						{shopRating &&
							shopRating.map((el, index) => {
								return (
									<div className="d-flex align-items-center justify-content-between p-1" key={`meal_${index}`}>
										<button className="primary_btn small_btn min_width_90 mr-2">{el.ratingRange}</button>
										<div className="font_weight_medium ellipsis">{el.shopCount}間店家</div>
									</div>
								);
							})}
					</div>
				) : (
					<div className="custom_no_data">未找到数据...</div>
				)}
			</div>
		</div>
	);
};
