export enum Language {
	ENGLISH = "en",
	JAPANESE = "ja",
}

export enum APIStatus {
	FULFILLED = "FULFILLED",
	REJECTED = "REJECTED",
}

export const priceRegex = /^[0-9]+$/;

export const quantityRegex = /^[0-9]+((,|\.)?[0-9])*\s?(g|kg|Kg|KG|ml|L|個|キログラム|グラム)?$/;

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const phoneNumberRegex = /^\d{2}(?:-\d{4}-\d{4}|\d{8}|\d-\d{3,4}-\d{4})$/;

export const numberRegex = /^[0-9]+$/;

export const taiwanPhoneNumberRegex = /^(0\d{1,2}-?\d{3,4}-?\d{4})|(09\d{2}-?\d{3}-?\d{3})|(\+88\s01\d{9})$/;

export const minimumBudgetRegex = /^\d*(\.\d+)?$/;

export enum LocalStorageKeys {
	TOKEN = "TOKEN",
	FRANCHISE_POSITIONING = "FRANCHISE_POSITIONING",
	ADVANCED_ANALYSIS = "ADVANCED_ANALYSIS",
	ANALYZE_LOCATION = "ANALYZE_LOCATION",
	BRAND_SELECT = "BRAND_SELECT",
	DISCOUNT = "DISCOUNT",
}

export enum Roles {
	ADMIN = "ADMIN",
	USER = "USER",
}
