import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { CitiesData, StoreDataState } from "../../modal/franchise-select";
import { Pagination } from "../../modal/common";
import { clearGetAllCitiesData } from "../../services/franchise-select/franchise-select-slice";
import { getAllCities } from "../../services/franchise-select/franchise-select.service";

type CityDropDownProps = {
	value: string | null;
	onChange(e: string | null): void;
	disabled: boolean;
	storeData: StoreDataState;
};

const CityDropdown: React.FunctionComponent<CityDropDownProps> = (props) => {
	const Option = Select;
	const cities = useAppSelector((store) => store.franchiseSelect.cities);
	const [pagination, setPagination] = useState<Pagination>({
		offset: 0,
		numResults: 50,
	});
	const dispatch = useAppDispatch();
	const franchiseSelectReducer = useAppSelector((store) => store.franchiseSelect);

	useEffect(() => {
		if (props.storeData.state) {
			clearGetAllCitiesData();
			let query = `offset=${pagination.offset}&numResults=${pagination.numResults}`;
			query += `&state=${props.storeData.state}`;
			dispatch(getAllCities(query));
		}
	}, [props.storeData.state, pagination.offset]);

	const scrollChanged = () => {
		if (franchiseSelectReducer.cities.length < franchiseSelectReducer.totalCities) {
			setPagination((prevState) => ({ ...prevState, offset: prevState.offset + 1 }));
		}
	};

	return (
		<Select
			className={props.value ? "custom_select data_select" : "custom_select"}
			disabled={props.disabled}
			placeholder="地區"
			value={props.value}
			onChange={(value) => props.onChange(value)}
			onPopupScroll={scrollChanged}
		>
			{cities.map((el: CitiesData, i: number) => {
				return <Option key={`city_${i}`} value={el.city} />;
			})}
		</Select>
	);
};

export default CityDropdown;
