import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../../modal/hooks";
import { DeliveryPlatform } from "../../constants/analyze-location/delivery-platform";

declare global {
	interface Window {
		google: typeof google;
	}
}

type AnalyzeLocationMapProps = {
	location: google.maps.LatLngLiteral;
	type: string;
};
export const AnalyzeLocationMap: React.FunctionComponent<AnalyzeLocationMapProps> = (props) => {
	const mapContainerRef = useRef<HTMLDivElement>(null);
	const shopLocation = useAppSelector((store) => store.analyzeLocation.shopLocation);
	const googleMapData = useAppSelector((store) => store.analyzeLocation.googleMapData);
	const mapRef = useRef<google.maps.Map | null>(null);
	const markersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);

	useEffect(() => {
		function clearMarkers() {
			markersRef.current.forEach((marker) => {
				marker.map = null;
			});
			markersRef.current = [];
		}

		async function showGoogleMapMarkers() {
			if (googleMapData.length) {
				const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
					"marker"
				)) as google.maps.MarkerLibrary;
				const length = googleMapData.length < 30 ? googleMapData.length : 30;
				const googleMapLength = props.type === DeliveryPlatform.ALL ? 10 : length;
				for (let restaurant = 0; restaurant < googleMapLength; restaurant++) {
					const customPin = new PinElement({
						glyphColor: "white",
						background: "#4A89F3",
						borderColor: "black",
					});
					const marker = new AdvancedMarkerElement({
						map: mapRef.current,
						position: googleMapData[restaurant]?.geometry?.location,
						content: customPin.element,
						title: googleMapData[restaurant]?.name,
					});
					markersRef.current.push(marker);
				}
			}
		}

		function showFoodPandaAndUberEatsMarkers() {
			if (shopLocation.length) {
				const markerPromise = google.maps.importLibrary("marker") as Promise<google.maps.MarkerLibrary>;
				markerPromise
					.then(({ AdvancedMarkerElement, PinElement }) => {
						shopLocation.forEach((el) => {
							const customPin = new PinElement({
								glyphColor: el.type === DeliveryPlatform.UBER_EATS ? "green" : "white",
								background: el.type === DeliveryPlatform.UBER_EATS ? "black" : "#D70F64",
								borderColor: el.type === DeliveryPlatform.UBER_EATS ? "green" : "#891349",
							});

							const marker = new AdvancedMarkerElement({
								map: mapRef.current,
								position: { lat: el.latitude, lng: el.longitude },
								content: customPin.element,
								title: el.name,
							});
							markersRef.current.push(marker);
						});
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}

		switch (props.type) {
		case DeliveryPlatform.GOOGLE_MAPS:
			clearMarkers();
			showGoogleMapMarkers();
			break;
		case DeliveryPlatform.FOOD_PANDA:
		case DeliveryPlatform.UBER_EATS:
			clearMarkers();
			showFoodPandaAndUberEatsMarkers();
			break;
		case DeliveryPlatform.ALL:
			clearMarkers();
			showGoogleMapMarkers();
			if (shopLocation.length) {
				showFoodPandaAndUberEatsMarkers();
			}
			break;
		default:
			break;
		}
	}, [shopLocation, googleMapData]);

	useEffect(() => {
		async function initMap(): Promise<void> {
			if (mapContainerRef.current) {
				const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
				mapRef.current = new Map(mapContainerRef.current, {
					center: props.location,
					zoom: 15,
					mapId: "GMAP",
				});

				const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
					"marker"
				)) as google.maps.MarkerLibrary;
				const customPin = new PinElement({
					glyphColor: "white",
					background: "#DD4B3E",
					borderColor: "Black",
				});

				const marker = new AdvancedMarkerElement({
					map: mapRef.current,
					position: props.location,
					content: customPin.element,
				});
			}
		}

		initMap();
	}, [mapContainerRef.current]);

	return (
		<div className="location_map_container">
			<div ref={mapContainerRef} />
		</div>
	);
};
