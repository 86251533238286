import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";

export const getBrandList = createAsyncThunk("get/list", async () => {
	const response = await axios.get(Config.apiUrl + "/brands", axiosConfig());
	return response.data;
});

export const getDiscountList = createAsyncThunk("get/discount-list", async () => {
	const response = await axios.get(Config.apiUrl + "/discountedBrands", axiosConfig());
	return response.data;
});

export const getEquipments = createAsyncThunk("get/equipments", async (id: number) => {
	const response = await axios.get(Config.apiUrl + `/equipments?brandId=${id}`, axiosConfig());
	return response.data;
});
