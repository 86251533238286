import React from "react";
import { LocalStorageKeys } from "../../constants";
import { Locations } from "../../constants/locations";

type AuthRouterProps = {
	children: React.ReactElement;
};

export const AuthRouter = (props: AuthRouterProps) => {
	const token = localStorage.getItem(LocalStorageKeys.TOKEN);
	if (token && [Locations.LOGIN, Locations.REGISTRATION_PAGE].includes(window.location.pathname as Locations)) {
		window.location.href = Locations.BASE;
		return null;
	} else if (token && !["null", "undefined"].includes(token ?? "")) {
		return props.children;
	} else if (
		!token &&
		[
			Locations.BASE,
			Locations.LOGIN,
			Locations.REGISTRATION_PAGE,
			Locations.FRANCHISE_POSITIONING,
			Locations.DEVELOP_BRAND,
			Locations.PREDICTION_RESULTS,
			Locations.ADVANCE_ANALYSIS,
		].includes(window.location.pathname as Locations)
	) {
		return props.children;
	} else if (window.location.pathname.includes(Locations.BRAND_DETAIL)) {
		return props.children;
	} else {
		localStorage.removeItem(LocalStorageKeys.TOKEN);
		window.location.href = Locations.BASE;
		return null;
	}
};
