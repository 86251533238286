import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { ChartRatingData } from "../../modal/analyze-location";
import "./donut-chart.scss";

export interface DonutChartProps {
	data: ChartRatingData[];
	id: string;
}

export const DonutChart: React.FunctionComponent<DonutChartProps> = (props) => {
	useEffect(() => {
		if (props.data.length) {
			Highcharts.chart(props.id, {
				chart: {
					type: "pie",
				},
				legend: {
					verticalAlign: "bottom",
					align: "left",
				},
				title: {
					text: "",
				},
				tooltip: {
					pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
				},
				accessibility: {
					point: {
						valueSuffix: "%",
					},
				},
				colors: ["#fd8324", "#07554a", "#35383F", "#EFEFEF"],
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						innerSize: "50%",
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						data: props.data,
						type: "pie",
					},
				],
			});
		}
	}, [props.data, props.id]);

	return <div className="custom_chart_container" id={props.id}></div>;
};
