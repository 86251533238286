import React from "react";

export const FoodFabSvg: React.FunctionComponent = () => {
	return (
		<svg width="70" height="46" viewBox="0 0 70 46" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1276_4099)">
				<path
					d="M52.8952 34.8696H51.223V34.0542H51.1964C50.6936 34.7658 49.8975 35.0273 49.1662 35.0273C47.136 35.0273 46.3894 33.3427 46.3894 31.5158C46.3894 29.6735 47.136 28.0427 49.1662 28.0427C50.0651 28.0427 50.6707 28.4196 51.1468 29.0542H51.1735V27.9004H52.8952V34.8696ZM51.1621 31.5119C51.1621 30.5773 50.724 29.385 49.6194 29.385C48.4996 29.385 48.1949 30.6158 48.1949 31.5119C48.1949 32.4312 48.5148 33.6773 49.6461 33.6773C50.7888 33.6773 51.1621 32.4581 51.1621 31.5119Z"
					fill=""
				/>
				<path
					d="M19.0103 28.0391C20.9757 28.0391 22.4803 29.3737 22.4803 31.5775C22.4803 33.4968 21.2081 35.0237 19.0103 35.0237C16.8277 35.0237 15.5555 33.4929 15.5555 31.5775C15.5555 29.3737 17.0563 28.0391 19.0103 28.0391ZM19.0103 33.6775C20.332 33.6775 20.6825 32.4852 20.6825 31.3698C20.6825 30.3468 20.1416 29.3852 19.0103 29.3852C17.8943 29.3852 17.3534 30.3698 17.3534 31.3698C17.3534 32.4737 17.7 33.6775 19.0103 33.6775Z"
					fill=""
				/>
				<path
					d="M27.47 28.0391C29.4355 28.0391 30.94 29.3737 30.94 31.5775C30.94 33.4968 29.6678 35.0237 27.47 35.0237C25.2875 35.0237 24.0153 33.4929 24.0153 31.5775C24.0153 29.3737 25.5198 28.0391 27.47 28.0391ZM27.47 33.6775C28.7917 33.6775 29.1422 32.4852 29.1422 31.3698C29.1422 30.3468 28.6013 29.3852 27.47 29.3852C26.3502 29.3852 25.8131 30.3698 25.8131 31.3698C25.8131 32.4737 26.1597 33.6775 27.47 33.6775Z"
					fill=""
				/>
				<path
					d="M37.3696 34.1196H37.343C36.8288 34.7696 36.1241 35.0273 35.3128 35.0273C33.2826 35.0273 32.536 33.3427 32.536 31.5158C32.536 29.6735 33.2826 28.0427 35.3128 28.0427C36.1736 28.0427 36.7792 28.3389 37.2935 28.9773H37.3201V25.6504H39.0418V34.8735H37.3696V34.1196ZM35.7889 33.6773C36.9316 33.6773 37.3049 32.4581 37.3049 31.5119C37.3049 30.5773 36.8668 29.385 35.7622 29.385C34.6462 29.385 34.3377 30.6158 34.3377 31.5119C34.3377 32.435 34.6576 33.6773 35.7889 33.6773Z"
					fill=""
				/>
				<path
					d="M55.1157 25.6465H56.8374V29.0503H56.8641C57.3402 28.4157 57.942 28.0388 58.8409 28.0388C60.8711 28.0388 61.6177 29.6734 61.6177 31.5119C61.6177 33.3388 60.8711 35.0234 58.8409 35.0234C58.1096 35.0234 57.3135 34.7657 56.8107 34.0503H56.7841V34.8657H55.1119V25.6465H55.1157ZM58.3648 33.6773C59.4961 33.6773 59.8161 32.4311 59.8161 31.5119C59.8161 30.6157 59.5075 29.3849 58.3915 29.3849C57.2869 29.3849 56.8488 30.5773 56.8488 31.5119C56.8488 32.458 57.2221 33.6773 58.3648 33.6773Z"
					fill=""
				/>
				<path
					d="M12.8587 27.4336C12.8587 26.9682 13.3234 26.2105 13.9786 26.0297L13.1025 24.8066C11.9674 24.9566 11.1371 26.1182 11.1371 27.3336V28.2605H9.86487V29.5297H11.1371V35.0259L12.8587 34.1913V29.5297H14.3633V28.2605H12.8587V27.4336Z"
					fill=""
				/>
				<path
					d="M43.7307 27.3652C43.7307 26.8998 44.1954 26.1421 44.8505 25.9614L43.9745 24.7383C42.8394 24.8883 42.009 26.0498 42.009 27.2652V28.2614H40.7368V29.5306H42.009V35.0267L43.7307 34.1921V29.5306H45.2352V28.2614H43.7307V27.3652Z"
					fill=""
				/>
				<path
					d="M54.5406 45.5H16.283C11.4075 45.4423 7.20234 43.6077 4.43701 40.3308C1.97259 37.4115 0.696571 33.4385 0.749897 28.8385C1.12318 19.45 7.76988 16.6538 12.0855 16.3692C12.6987 14.2038 14.409 10.1192 18.8122 8.23846C22.8916 6.49615 26.4911 7.51154 28.3423 8.31923C29.8773 5.70385 34.0291 0.5 42.687 0.5C53.5845 0.5 57.8392 9.11538 58.8066 14.6808C63.1489 15.4462 69.7499 19.0231 69.7499 28.5846C69.7499 41.9654 59.8008 45.5 54.5406 45.5ZM16.3058 42.1346H54.5406C55.0434 42.1346 66.8398 41.9808 66.8398 28.5808C66.8398 18.6808 57.8354 17.9192 57.4506 17.8923L56.2165 17.8077L56.0908 16.3808C56.0451 15.8731 54.8072 3.86538 42.687 3.86538C33.313 3.86538 30.3725 10.8692 30.2544 11.1692L29.5878 12.8231L28.2166 11.9308C28.0642 11.8346 24.3276 9.47308 19.8216 11.4C15.4412 13.2692 14.6756 18.1077 14.6451 18.3115L14.4318 19.7654L13.1596 19.7192C12.8016 19.7077 4.03706 19.4962 3.66378 28.9423C3.62188 32.6231 4.58175 35.6808 6.52434 37.9808C8.73737 40.6115 12.215 42.0846 16.3058 42.1346Z"
					fill=""
				/>
			</g>
			<defs>
				<clipPath id="clip0_1276_4099">
					<rect width="69" height="45" fill="white" transform="translate(0.75 0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
