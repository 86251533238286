import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { DonutChart } from "../../components/donut-chart";
import { AnalyzeLocationMap } from "../../components/analyze-location-map";
import { Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import {
	getAllShopCount,
	getAllShopLocation,
	getAllMostPopularCuisine,
	getAllNumerousCategories,
	getAllShopRating,
} from "../../services/analyze-location/analyze-location.service";
import { APIStatus, LocalStorageKeys } from "../../constants";
import { DeliveryPlatform } from "../../constants/analyze-location/delivery-platform";
import { MealsOnEachPlatform } from "../../components/meals-on-each-platform";
import { MealPeriod } from "../../components/meal-period";
import {
	clearGetAllMostPopularCuisine,
	clearGetAllNumerousCategories,
	clearGetAllShopCount,
	clearGetAllShopLocation,
	clearGetAllShopLocationData,
	clearGetAllShopRating,
	clearGoogleMapData,
	setGoogleMapData,
} from "../../services/analyze-location/analyze-location.slice";
import { Loading } from "../../components/loading";
import "./analyze-location.scss";
import { showInfo } from "../../utils/util.fns";
import { AnalyzeLocationHandles } from "../../modal/analyze-location";
import { PlanTypes } from "../../constants/franchise-select/planTypes";

type AnalyzeLocationModalProps = {
	handleClick?: () => boolean;
};

const AnalyzeLocationModal: React.ForwardRefRenderFunction<AnalyzeLocationHandles, AnalyzeLocationModalProps> = (
	props,
	ref
) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const analyzeLocation = useAppSelector((store) => store.analyzeLocation);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const loc = useLocation();
	const params = new URLSearchParams(loc.search);
	const location = params.get("location");
	const [mapTypeVariable, setMapTypeVariable] = useState<string>(DeliveryPlatform.ALL);
	const brandExists = params.get("brandExists");
	const mapRef = useRef<HTMLDivElement>(null);
	const [googleMapTotalCount, setGoogleMapTotalCount] = useState<number>(0);
	const [responseStatus, setResponseStatus] = useState<string>("");
	const googlePlacesAPIStatus = google.maps.places?.PlacesServiceStatus;
	const discountBrandList = useAppSelector((store) => store.brandSelect.discountList);
	const addedBrands = useAppSelector((store) => store.brandSelect.addedBrands);

	const AnalyzeLocationMapMemo = useMemo(() => {
		if (location) {
			return (
				<AnalyzeLocationMap
					location={JSON.parse(location)}
					type={mapTypeVariable === "" ? DeliveryPlatform.ALL : mapTypeVariable}
				/>
			);
		} else {
			return <div></div>;
		}
	}, [location, mapTypeVariable]);

	useEffect(() => {
		if (location) {
			fetchShopLocationAndCount(mapTypeVariable ?? DeliveryPlatform.ALL);
			fetchGoogleMapData();
		} else {
			setIsModalOpen(false);
		}
		return () => {
			dispatch(clearGetAllShopLocationData());
		};
	}, [location]);

	useImperativeHandle(ref, () => ({
		closeModal: () => {
			setIsModalOpen(false);
		},
	}));

	const loaded = useMemo(
		() =>
			(analyzeLocation.getAllNumerousCategoriesComplete === APIStatus.FULFILLED ||
				analyzeLocation.getAllNumerousCategoriesComplete === APIStatus.REJECTED) &&
			(analyzeLocation.getAllMostPopularCuisineComplete === APIStatus.FULFILLED ||
				analyzeLocation.getAllMostPopularCuisineComplete === APIStatus.REJECTED) &&
			(analyzeLocation.getAllShopLocationComplete === APIStatus.FULFILLED ||
				analyzeLocation.getAllShopLocationComplete === APIStatus.REJECTED) &&
			(analyzeLocation.getAllShopCountComplete === APIStatus.FULFILLED ||
				analyzeLocation.getAllShopCountComplete === APIStatus.REJECTED) &&
			(analyzeLocation.getAllShopRatingComplete === APIStatus.FULFILLED ||
				analyzeLocation.getAllShopRatingComplete === APIStatus.REJECTED) &&
			(responseStatus === googlePlacesAPIStatus?.OK ||
				responseStatus === googlePlacesAPIStatus?.INVALID_REQUEST ||
				responseStatus === googlePlacesAPIStatus?.NOT_FOUND ||
				responseStatus === googlePlacesAPIStatus?.OVER_QUERY_LIMIT ||
				responseStatus === googlePlacesAPIStatus?.REQUEST_DENIED ||
				responseStatus === googlePlacesAPIStatus?.UNKNOWN_ERROR ||
				responseStatus === googlePlacesAPIStatus?.ZERO_RESULTS),
		[
			analyzeLocation.getAllNumerousCategoriesComplete,
			analyzeLocation.getAllMostPopularCuisineComplete,
			analyzeLocation.getAllShopLocationComplete,
			analyzeLocation.getAllShopCountComplete,
			analyzeLocation.getAllShopRatingComplete,
			responseStatus,
		]
	);

	const onNextClicked = () => {
		navigate(Locations.DEVELOP_BRAND + `${loc.search}`);
	};

	const clearLocationData = () => {
		dispatch(clearGetAllNumerousCategories());
		dispatch(clearGetAllMostPopularCuisine());
		dispatch(clearGetAllShopLocation());
		dispatch(clearGetAllShopCount());
		dispatch(clearGetAllShopLocationData());
		dispatch(clearGetAllShopRating());
		dispatch(clearGoogleMapData());
	};

	const handleClick = () => {
		if (location) {
			fetchShopLocationAndCount(mapTypeVariable ?? DeliveryPlatform.ALL);
			fetchGoogleMapData();
		}
		if (props.handleClick && props.handleClick()) {
			setIsModalOpen(true);
		} else if (!props.handleClick) {
			setIsModalOpen(true);
		}
	};

	const reEnterLocationClicked = () => {
		if (loc.pathname.includes(Locations.FRANCHISE_POSITIONING)) {
			setIsModalOpen(false);
			navigate(Locations.FRANCHISE_POSITIONING + `?brandExists=${brandExists}` + `&planType=${params.get("planType")}`);
		} else {
			navigate(Locations.FRANCHISE_POSITIONING + `?brandExists=${brandExists}` + `&planType=${params.get("planType")}`);
		}
	};

	const fetchShopLocationAndCount = (type: string) => {
		const locationWithLatLng = location != null ? JSON.parse(location) : "";
		const popularCuisineQuery = `deliveryPlatform=${type}&lat=${locationWithLatLng.lat}&lng=${locationWithLatLng.lng}`;
		const shopLocationQuery = `latitude=${locationWithLatLng.lat}&longitude=${locationWithLatLng.lng}${
			type !== DeliveryPlatform.ALL ? `&type=${type}` : ""
		}`;
		const shopCountRateQuery = `latitude=${locationWithLatLng.lat}&longitude=${locationWithLatLng.lng}`;
		dispatch(getAllMostPopularCuisine(popularCuisineQuery));
		dispatch(getAllNumerousCategories(popularCuisineQuery));
		dispatch(getAllShopLocation(shopLocationQuery));
		dispatch(getAllShopCount(shopCountRateQuery));
		dispatch(getAllShopRating(shopCountRateQuery));
	};

	const setMapType = (type: string) => {
		setMapTypeVariable(type);
		dispatch(clearGetAllShopLocationData());
		fetchShopLocationAndCount(type);
	};

	const onClosedClicked = () => {
		clearLocationData();
		setIsModalOpen(false);
		if (loc.pathname.includes(Locations.FRANCHISE_POSITIONING)) {
			const searchParams = new URLSearchParams(loc.search);
			searchParams.delete("location");
			navigate(`${loc.pathname}?${searchParams.toString()}`, { replace: true });
		}
	};

	function nearbySearchCallback(
		results: google.maps.places.PlaceResult[] | null,
		status: google.maps.places.PlacesServiceStatus
	) {
		setResponseStatus(status);
		if (status == google.maps.places.PlacesServiceStatus.OK && results?.length) {
			dispatch(setGoogleMapData(results));
			setGoogleMapTotalCount(results?.length);
		}
	}

	const fetchGoogleMapData = async () => {
		const locationWithLatLng = location != null ? JSON.parse(location) : "";
		if (mapRef.current) {
			const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;
			const request: any = {
				location: { lat: locationWithLatLng.lat, lng: locationWithLatLng.lng },
				radius: 1000,
				type: "restaurant",
			};
			const { PlacesService } = (await google.maps.importLibrary("places")) as google.maps.PlacesLibrary;
			const service = new PlacesService(
				new Map(mapRef.current, {
					center: { lat: locationWithLatLng.lat, lng: locationWithLatLng.lng },
					zoom: 15,
					mapId: "GMAP",
				})
			);
			service.nearbySearch(request, nearbySearchCallback);
		}
	};

	const resultClicked = () => {
		if (
			(params.get("planType") === PlanTypes.CLOUD_STORE ||
				params.get("planType") === PlanTypes.MICRO_STORE ||
				params.get("planType") === PlanTypes.STANDARD_STORE) &&
			addedBrands.length === 0
		) {
			showInfo("至少应选择 1");
		} else if (addedBrands) {
			const discounted = discountBrandList.every((el) => addedBrands && addedBrands.some((d) => d.id === el.brandId));
			navigate(Locations.PREDICTION_RESULTS + `?planType=${params.get("planType")}` + `&discount=${discounted}`);
			localStorage.setItem(LocalStorageKeys.BRAND_SELECT, JSON.stringify([...addedBrands]));
		}
	};

	return (
		<>
			<div hidden ref={mapRef}></div>
			{isModalOpen ? (
				<Modal className="analyze_location_modal" open={true} footer={[]} closable={false}>
					<div className="main_container analyze_location_container d-flex flex-column">
						<div className="close_btn" onClick={onClosedClicked}>
							<img className="cursor-pointer" src="/images/close-btn.png" alt="img" />
						</div>
						<div className="text-center pb-2">
							<div className="main_title font_24">您的店舖位置分析報告</div>
							<div className="sub_title">根據您的資訊我們分析出這個地區的餐飲品牌數據</div>
						</div>
						<div className="flex_1_1_10 overflow-auto">
							<div className="d-flex my-2 px-1">
								<div className="map_container mr-2" id="v-pills-tabContent" tabIndex={0}>
									{location ? AnalyzeLocationMapMemo : ""}
								</div>
								<div className="meal_platform_container ml-2">
									<MealsOnEachPlatform
										setMapType={setMapType}
										googleMapTotalCount={googleMapTotalCount}
										mapTypeVariable={mapTypeVariable}
									/>
								</div>
							</div>
							<div className="d-flex px-1">
								<div className="graph_data_card_box flex_1_1_10">
									<MealPeriod />
								</div>
								<div className="graph_data_card_box flex_1_1_10 mx-3">
									<div className="platform_content_box d-flex flex-column overflow-hidden">
										<div className="content_box_sub_title">數量最多菜系</div>
										<div className="flex_1_1_10">
											{analyzeLocation.popularCuisines.length && mapTypeVariable !== DeliveryPlatform.GOOGLE_MAPS ? (
												<DonutChart data={analyzeLocation?.popularCuisines} id="most_cuisine" />
											) : (
												<div className="custom_no_data">未找到数据...</div>
											)}
										</div>
									</div>
								</div>
								<div className="graph_data_card_box flex_1_1_10">
									<div className="platform_content_box d-flex flex-column overflow-hidden">
										<div className="content_box_sub_title">數量最多品類</div>
										<div className="flex_1_1_10">
											{analyzeLocation.numerousCategories.length && mapTypeVariable !== DeliveryPlatform.GOOGLE_MAPS ? (
												<DonutChart data={analyzeLocation?.numerousCategories} id="most_category" />
											) : (
												<div className="custom_no_data">未找到数据...</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-center pt-2">
							<button className="primary_border_btn mr-2" onClick={reEnterLocationClicked}>
								重新輸入位置
							</button>
							{loc.pathname.includes(Locations.FRANCHISE_POSITIONING) ? (
								<button onClick={onNextClicked} className="primary_btn">
									下一步：制定品牌
								</button>
							) : (
								""
							)}
						</div>
					</div>
				</Modal>
			) : (
				""
			)}
			{isModalOpen && !loaded ? <Loading /> : ""}
			{loc.pathname.includes(Locations.FRANCHISE_POSITIONING) ? (
				<div className="basic_footer_container">
					<button className="primary_btn min_width_220" onClick={handleClick}>
						分析店舖位置
					</button>
				</div>
			) : (
				<div className="basic_footer_container">
					<button
						onClick={() => navigate(Locations.FRANCHISE_POSITIONING + `?brandExists=${brandExists}`)}
						className="primary_border_btn mr-2"
					>
						上一步
					</button>
					<button className="primary_btn mr-2" onClick={handleClick}>
						參考分析結果
					</button>
					<button className={"secondary_btn"} onClick={resultClicked}>
						下一步：預測結果
					</button>
				</div>
			)}
		</>
	);
};

export default forwardRef(AnalyzeLocationModal);
