import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { getAllNumerousCategories, getAllMostPopularCuisine, getAllShopLocation, getAllShopCount, getAllShopRating } from "./analyze-location.service";
import { AnalyzeLocationReducer, ShopLocationCount } from "../../modal/analyze-location";

const initialState: AnalyzeLocationReducer = {
	getAllNumerousCategoriesComplete: null,
	getAllMostPopularCuisineComplete: null,
	numerousCategories : [],
	popularCuisines : [],
	getAllShopLocationComplete: null,
	getAllShopCountComplete: null,
	shopLocation: [],
	shopCount: {} as ShopLocationCount,
	getAllShopRatingComplete: null,
	shopRating: [],
	googleMapData: []
};

const analyzeLocationSlice = createSlice({
	name: "analyzeLocation",
	initialState,
	reducers: {
		clearGetAllNumerousCategories(state) {
			state.getAllNumerousCategoriesComplete = null;
		},
		clearGetAllMostPopularCuisine(state) {
			state.getAllMostPopularCuisineComplete = null;
		},
		clearGetAllShopLocation(state) {
			state.getAllShopLocationComplete = null;
		},
		clearGetAllShopLocationData(state) {
			state.shopLocation = [];
		},
		clearGetAllShopCount(state) {
			state.getAllShopCountComplete = null;
		},
		clearGetAllShopRating(state) {
			state.getAllShopRatingComplete = null;
		},
		setGoogleMapData(state, action) {
			state.googleMapData = action.payload;
		},
		clearGoogleMapData(state) {
			state.googleMapData = [];
		}
	},
	extraReducers(builder) {
		builder.addCase(getAllNumerousCategories.fulfilled, (state, action) => {
			state.getAllNumerousCategoriesComplete = APIStatus.FULFILLED;
			state.numerousCategories = action.payload.data;
		}); 
		builder.addCase(getAllNumerousCategories.rejected, (state) => {
			state.getAllNumerousCategoriesComplete = APIStatus.REJECTED;	
		});
		builder.addCase(getAllMostPopularCuisine.fulfilled, (state, action) => {
			state.getAllMostPopularCuisineComplete = APIStatus.FULFILLED;
			state.popularCuisines = action.payload.data;
		});
		builder.addCase(getAllMostPopularCuisine.rejected, (state) => {
			state.getAllMostPopularCuisineComplete = APIStatus.REJECTED;
		});
		builder.addCase(getAllShopLocation.fulfilled, (state, action) => {
			state.getAllShopLocationComplete = APIStatus.FULFILLED;
			state.shopLocation = action.payload.data;
		});
		builder.addCase(getAllShopLocation.rejected, (state) => {
			state.getAllShopLocationComplete = APIStatus.REJECTED;
		});
		builder.addCase(getAllShopCount.fulfilled, (state, action) => {
			state.getAllShopCountComplete = APIStatus.FULFILLED;
			state.shopCount = action.payload.data;
		});
		builder.addCase(getAllShopCount.rejected, (state) => {
			state.getAllShopCountComplete = APIStatus.REJECTED;
		});
		builder.addCase(getAllShopRating.fulfilled, (state, action) => {
			state.getAllShopRatingComplete = APIStatus.FULFILLED;
			state.shopRating = action.payload.data;
		});
		builder.addCase(getAllShopRating.rejected, (state) => {
			state.getAllShopRatingComplete = APIStatus.REJECTED;
		});
	},
});

export const { 
	clearGetAllNumerousCategories, 
	clearGetAllMostPopularCuisine, 
	clearGetAllShopLocation,
	clearGetAllShopCount,
	clearGetAllShopLocationData,
	clearGetAllShopRating,
	setGoogleMapData,
	clearGoogleMapData
} = analyzeLocationSlice.actions;

export default analyzeLocationSlice.reducer;
