import React from "react";
import "./footer.scss";

export const Footer: React.FunctionComponent = () => {
	return (
		<div className="custom_footer_container">
			<div className="custom_footer_content">
				<img src="/images/brand-details/white-logo.png" />
				<div className="custom_footer_label mt-3 mb-4">加入腹飛，營收翻倍！</div>
				<div className="custom_footer_copy_right_box">
					<div>腹飛雲廚股份有限公司</div>
					<div className="custom_footer_copy_right">© 2020 foodfab. All rights reserved.</div>
				</div>
			</div>
		</div>
	);
};
