import React, { forwardRef, useImperativeHandle, useState } from "react";
import CityDropdown from "../../components/city-dropdown";
import { FranchiseSelectHandles, Invalid, StoreDataState } from "../../modal/franchise-select";
import "./franchise-select.scss";
import StateDropDown from "../state-dropdown";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FoodFabSvg } from "../../assets/svg/food-fab-svg";
import { MicroStoreSvg } from "../../assets/svg/micro-store-svg";
import { StandardStoreSvg } from "../../assets/svg/standard-store-svg";
import planType from "../../constants/franchise-select/planType.json";
import { useLocation, useNavigate } from "react-router-dom";
import { PlanTypes } from "../../constants/franchise-select/planTypes";
import { minimumBudgetRegex } from "../../constants";
import { useAppSelector } from "../../modal/hooks";

const FranchiseSelectForm: React.ForwardRefRenderFunction<FranchiseSelectHandles> = (props, ref) => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const cities = useAppSelector((store) => store.franchiseSelect.cities);
	const [storeData, setStoreData] = useState<StoreDataState>({
		minimumBudget: "",
		maximumBudget: "",
		planType: params.get("brandExists") === "false" ? PlanTypes.CLOUD_STORE : PlanTypes.MICRO_STORE,
		cities: null,
		state: null,
		address: "",
		revenueMultiple: 0,
	});
	const navigate = useNavigate();
	const [invalid, setInvalid] = useState<Invalid>({
		cities: false,
		state: false,
		minimumBudget: false,
		maximumBudget: false,
		budgetComparison: false,
	});

	useImperativeHandle(ref, () => ({
		isValid: (): boolean => {
			const prevState: Invalid = JSON.parse(JSON.stringify(invalid));
			let valid = true;
			if (!minimumBudgetRegex.test(storeData.minimumBudget) || (storeData.maximumBudget && !storeData.minimumBudget)) {
				prevState.minimumBudget = true;
				valid = false;
			}
			if (!minimumBudgetRegex.test(storeData.maximumBudget) || (storeData.minimumBudget && !storeData.maximumBudget)) {
				prevState.maximumBudget = true;
				valid = false;
			}
			if (
				storeData.minimumBudget &&
				storeData.maximumBudget &&
				parseFloat(storeData.minimumBudget) > parseFloat(storeData.maximumBudget) &&
				!prevState.minimumBudget &&
				!prevState.maximumBudget
			) {
				prevState.budgetComparison = true;
				valid = false;
			}
			if (!storeData.cities) {
				prevState.cities = true;
				valid = false;
			}
			if (!storeData.state) {
				prevState.state = true;
				valid = false;
			}
			setInvalid(prevState);
			return valid;
		},
		getValue: () => {
			return storeData;
		},
	}));

	const handlePlanTypeChange = (value: string) => {
		setStoreData((prevState) => ({ ...prevState, planType: value }));
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("planType", value);
		const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
		navigate(newUrl);
	};

	return (
		<div className="container">
			<div className="main_form_container pt-4">
				<div className="custom_input_field_container mb-4">
					<div className="custom_label">
						{params.get("planType") === PlanTypes.CLOUD_STORE ? "預算區間" : "您的預算區間"}
					</div>
					<div className="d-flex">
						<div className="custom_main_form d-flex align-items-center w-100">
							<div className="create_update_form_item w-100">
								<input
									type="number"
									value={storeData.minimumBudget}
									onChange={(e) => {
										setStoreData((prevState) => ({ ...prevState, minimumBudget: e.target.value.slice(0, 15) }));

										setInvalid((prevState) => ({
											...prevState,
											maximumBudget: false,
											minimumBudget: false,
											budgetComparison: false,
										}));
									}}
									placeholder="最低預算"
								/>
								{invalid.minimumBudget ? (
									<span className="invalid_message invalid_top_minus_18">输入有效的最低预算</span>
								) : (
									""
								)}
							</div>
							<div className="min_width_75 font_22 text-center pl-2">元～</div>
						</div>
						<div className="custom_main_form d-flex align-items-center w-100">
							<div className="create_update_form_item w-100">
								<input
									type="number"
									value={storeData.maximumBudget}
									onChange={(e) => {
										setStoreData((prevState) => ({ ...prevState, maximumBudget: e.target.value.slice(0, 15) }));
										setInvalid((prevState) => ({
											...prevState,
											minimumBudget: false,
											maximumBudget: false,
											budgetComparison: false,
										}));
									}}
									placeholder="最高預算 "
								/>
								{invalid.maximumBudget ? (
									<span className="invalid_message invalid_top_minus_18">输入有效的最高预算</span>
								) : (
									""
								)}
								{invalid.budgetComparison ? (
									<span className="invalid_message invalid_top_minus_18">最低预算必须大于最高预算</span>
								) : (
									""
								)}
							</div>
							<div className="min_width_75 font_22 text-center pr-2">元</div>
						</div>
					</div>
				</div>
				<div className="custom_input_field_container mb-4">
					<div className="custom_label">選擇加盟方案*</div>
					<div className="d-flex">
						{planType.map((el, index) => (
							<div
								key={`planType${index}`}
								className={`${storeData.planType === el.value ? "plan_card plan_card_active" : "plan_card"}`}
								onClick={() => handlePlanTypeChange(el.value)}
							>
								<div className="plan_content_icon_box d-flex align-items-center justify-content-center mb-3">
									{el.title === "雲端店" ? <FoodFabSvg /> : ""}
									{el.title === "微型店" ? <MicroStoreSvg /> : ""}
									{el.title === "標準店" ? <StandardStoreSvg /> : ""}
									<div className="plan_title">{el.title}</div>
								</div>
								<div className="plan_sub_title mb-3">{el.subTitle}</div>
								<div className="range_title mb-3">{el.rangeTitle}</div>
								<div className="range_title font_16 mb-3">{el.feeTitle}</div>
								<Checkbox
									className="custom_checkbox"
									value={el.value}
									checked={storeData.planType === el.value}
								></Checkbox>
							</div>
						))}
					</div>
				</div>
				<div className="custom_input_field_container mb-4">
					<div className="custom_label">
						{params.get("planType") === PlanTypes.CLOUD_STORE ? "店鋪地址*" : "全新店鋪想開在哪個地區*"}
					</div>
					<div className="d-flex mb-3">
						<div className="custom_main_form w-100 mr-2">
							<div className="create_update_form_item">
								<StateDropDown
									value={storeData.state}
									onChange={(e: string) => {
										setStoreData((prevState) => ({ ...prevState, state: e, cities: null }));
										setInvalid((prevState) => ({ ...prevState, state: false }));
									}}
								/>
								{invalid.state ? <span className="invalid_message invalid_top_minus_18">选择台北市</span> : ""}
							</div>
						</div>
						<div className="custom_main_form w-100 ml-2">
							<div className="create_update_form_item">
								<CityDropdown
									disabled={!storeData.state}
									value={storeData.cities}
									onChange={(e: string) => {
										const foundCity = cities.find((el) => el.city === e);
										setStoreData((prevState) => ({
											...prevState,
											cities: e,
											revenueMultiple: foundCity ? foundCity.revenueMultiple : 0,
										}));
										setInvalid((prevState) => ({ ...prevState, cities: false }));
									}}
									storeData={storeData}
								/>
								{invalid.cities ? <span className="invalid_message invalid_top_minus_18">选择区域</span> : ""}
							</div>
						</div>
					</div>
					<div className="custom_main_form w-100">
						<div className="create_update_form_item w-100">
							<input
								type="text"
								maxLength={250}
								value={storeData.address}
								onChange={(e) => {
									setStoreData((prevState) => ({ ...prevState, address: e.target.value }));
								}}
								placeholder="地址（非必填）"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default forwardRef(FranchiseSelectForm);
