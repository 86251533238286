import React from "react";

export const CloseSvg: React.FunctionComponent = () => {
	return (
		<svg width="10" height="11" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<line x1="1.06066" y1="1.65039" x2="10.6667" y2="11.2564" strokeWidth="1.5" strokeLinecap="round" />
			<line x1="1.33398" y1="11.2889" x2="10.94" y2="1.68293" strokeWidth="1.5" strokeLinecap="round" />
		</svg>
	);
};
