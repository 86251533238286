import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";

export const getBrandDetails = createAsyncThunk("get/details", async (id: number) => {
	const response = await axios.get(Config.apiUrl + `/brands/${id}`, axiosConfig());
	return response.data;
});

export const getKeyWords = createAsyncThunk("get/keywords", async (id: number) => {
	const response = await axios.get(Config.apiUrl + `/brands/${id}/keywords`, axiosConfig());
	return response.data;
});

export const getMenu = createAsyncThunk("get/menu", async (id: number) => {
	const response = await axios.get(Config.apiUrl + `/brands/${id}/menu`, axiosConfig());
	return response.data;
});
