import React, { useState } from "react";
import { useNavigate } from "react-router";
import { OnBoardingPage } from "../onBoardingpage";
import { Locations } from "../../constants/locations";
import { Header } from "../../components/header";

export const RegistrationPage: React.FunctionComponent = () => {
	const [isOnBoardingModalOpen, setIsOnboardingModalOpen] = useState(false);
	const navigate = useNavigate();
	return (
		<div className="white-bg h-100 d-flex flex-column overflow-auto">
			<div className="container">
				<Header
					toggles={[
						<div key={"brand_details_header_toggles"} className="header-right">
							<div className="login-details">
								<button id="join-us" className="primary_border_btn mr-2" onClick={() => setIsOnboardingModalOpen(true)}>
									加入我們
								</button>
								<button id="join-us" className="primary_border_btn ml-1" onClick={() => navigate(Locations.LOGIN)}>
									登入/註冊
								</button>
							</div>
						</div>,
					]}
				/>
			</div>
			<section className="login-container register-form flex_1_1_10">
				<div className="login-left h-100">
					<div className="form-block h-100">
						<h6 className="font_18 font_weight_black pt-4">Please Fill out form to Register!</h6>
						<form className="color_theme">
							<div className="single-input">
								<label>Fullname:</label>
								<input type="text" className="form-control" />
							</div>
							<div className="single-input">
								<label>Username:</label>
								<input type="text" className="form-control" />
							</div>
							<div className="single-input">
								<label>Email:</label>
								<input type="email" className="form-control" />
							</div>
							<div className="single-input">
								<label>Password:</label>
								<input type="password" className="form-control" />
							</div>
							<div className="single-input">
								<label>Confirm Password:</label>
								<input type="password" className="form-control" />
							</div>
							<div className="single-input btn-div">
								<button type="submit" className="secondary_btn w-100" onClick={() => navigate(Locations.BASE)}>
									Register
								</button>
							</div>
							<span className="font_16">
								Yes ! I have an account?
								<a className="cursor-pointer font_weight_medium px-1" onClick={() => navigate(Locations.LOGIN)}>
									Login
								</a>
							</span>
						</form>
						<ul className="social-icons">
							<li>
								<a target="_blank">
									<img src="/images/fb.png" alt="fb" />
								</a>
							</li>
							<li>
								<a target="_blank">
									<img src="/images/whatsapp.png" alt="whatsapp" />
								</a>
							</li>
							<li>
								<a target="_blank">
									<img src="/images/mail.png" alt="mail" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="login-right register_bg h-100"></div>
			</section>
			<>{isOnBoardingModalOpen ? <OnBoardingPage setIsOnBoardingModalOpen={setIsOnboardingModalOpen} /> : ""}</>
		</div>
	);
};
