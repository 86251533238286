import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getBrandDetails, getKeyWords, getMenu } from "../../services/brand-details/brand-details.service";
import { OnBoardingPage } from "../onBoardingpage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BrandKeyWords } from "../../components/brand-keywords";
import { Header } from "../../components/header";
import "./brand-details.scss";
import { Breadcrumb, Spin } from "antd";
import { Locations } from "../../constants/locations";
import { GlobeSvg } from "../../assets/svg/globe-svg";
import { LocationSvg } from "../../assets/svg/location-svg";
import { ClipboardSvg } from "../../assets/svg/clipboard-svg";
import LinesEllipsis from "react-lines-ellipsis";
import { Footer } from "../../components/footer";
import {
	clearBrandDetailsData,
	clearKeywordsData,
	clearMenuData,
} from "../../services/brand-details/brand-details.slice";

export const BrandDetails: React.FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const brandDetails = useAppSelector((store) => store.brandDetails.brandDetails);
	const brandDetailsMenu = useAppSelector((store) => store.brandDetails.brandDetailsMenu);
	const [isOnBoardingModalOpen, setIsOnboardingModalOpen] = useState(false);
	const { brandId } = useParams();
	const brandDetailsLoading = useAppSelector((store) => store.brandDetails.brandDetailsLoading);
	const loc = useLocation();

	useEffect(() => {
		dispatch(getBrandDetails(parseInt(brandId ? brandId : "")));
		dispatch(getMenu(parseInt(brandId ? brandId : "")));
		dispatch(getKeyWords(parseInt(brandId ? brandId : "")));
	}, []);

	useEffect(() => {
		return () => {
			dispatch(clearBrandDetailsData());
			dispatch(clearKeywordsData());
			dispatch(clearMenuData());
		};
	}, []);

	return (
		<div className="main_container">
			<div className="container">
				<Header
					toggles={[
						<div key={"brand_details_header_toggles"} className="header-right">
							<div className="login-details">
								<button className="primary_border_btn mr-2" onClick={() => setIsOnboardingModalOpen(true)}>
									加入我們
								</button>
								<button className="primary_border_btn" onClick={() => navigate(Locations.LOGIN)}>
									登入/註冊
								</button>
							</div>
						</div>,
					]}
				/>
				<div className="brand_details_content_container px-3">
					<div className="py-2">
						<Breadcrumb
							separator=">"
							items={[
								{
									title: (
										<span
											className="cursor-pointer"
											onClick={() => navigate(Locations.DEVELOP_BRAND + loc.search + `&addedBrands=${true}`)}
										>
											品牌一覽
										</span>
									),
								},

								{
									title: <span>{brandDetails.name}</span>,
								},
							]}
						/>
					</div>
					{brandDetailsLoading ? (
						<div className="text-center py-4">
							<Spin />
						</div>
					) : (
						<div>
							<div className="brand_banner_container mb-3">
								<div className="brand_banner_image">
									<img src={`${brandDetails.headerImage}`} />
								</div>
								<div className="brand_banner_content">
									<div className="d-flex">
										<div className="flex_1_1_10 width_10 pr-3">
											<div>
												<button className="secondary_btn mr-2 border_radius_4 font_12">
													{brandDetails.brandClass}
												</button>
												<button className="primary_btn border_radius_4 font_12">{brandDetails.type}</button>
											</div>
											<div className="brand_banner_name">
												<div className="brand_banner_name_text">{brandDetails.name}</div>
											</div>
										</div>
										<div className="flex_0_0_250 width_10 d-flex align-items-center justify-content-center brand_banner_logo">
											<img src={`${brandDetails.logoImage}`} alt="logo" />
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{/* --------------don't remove--------------*/}
					{/* <div className="brand_features_container white_card_box overflow-hidden mb-3">
						<div className="d-flex">
							<div className="flex_1_1_10 width_10 d-flex pr-3">
								<div className="brand_feature_box mr-3">
									<div className="brand_feature_box_image">
										<GlobeSvg />
									</div>
									<div className="brand_feature_box_name">
										<a href={brandDetails.brandWebsiteLink}>品牌官網</a>
									</div>
								</div>
								<div className="brand_feature_box mr-3">
									<div className="brand_feature_box_image">
										<LocationSvg />
									</div>
									<div className="brand_feature_box_name">
										<a href={brandDetails.brandStrongholdLink}>品牌據點</a>
									</div>
								</div>
								<div className="brand_feature_box">
									<div className="brand_feature_box_image">
										<ClipboardSvg />
									</div>
									<div className="brand_feature_box_name">
										<a href={brandDetails.equipmentMaterialsLink}>器材/備料</a>
									</div>
								</div>
							</div>
							<div className="flex_0_0_200 width_10">
								<button
									onClick={() => setIsOnboardingModalOpen(true)}
									className="primary_border_btn h-100 min_width_200"
								>
									我想加盟
								</button>
							</div>
						</div>
					</div> */}
					<div className="brand_description_container d-flex mb-3">
						<div className="d-flex flex-column brand_description_box white_card_box overflow-hidden flex_1_1_10 width_10 mr-3 position-relative">
							<div className="brand_description_title brand_main_title mb-2">品牌簡介</div>
							<div className="flex_1_1_10 brand_description_content overflow-auto word_break pb-3">
								{brandDetails.description}
							</div>
							<div className="content_blur"></div>
						</div>
						<div className="flex_0_0_300 width_10 brand_name_tag_box white_card_box overflow-hidden">
							<div className="brand_main_title">銷售排名</div>
							<div className="brand_name_tag_logo_text">{brandDetails.rating}</div>
						</div>
						<div className="return_joining_process_btn">
							<button
								className="primary_btn joining_process_btn min_width_260"
								onClick={() => navigate(Locations.DEVELOP_BRAND + loc.search + `&addedBrands=${true}`)}
							>
								返回選擇品牌
							</button>
						</div>
					</div>
					<div className="brand_details_menu_container d-flex mb-3">
						<div className="brand_details_menu_content white_card_box overflow-hidden flex_1_1_10 width_10 mr-3 position-relative">
							<div className="brand_main_title mb-3">菜單</div>
							<div className="brand_details_menu_content_box">
								{brandDetailsMenu.map((el, index: number) => {
									return (
										<div key={`brand_details_menu_${index}`}>
											<div className="brand_details_menu_content_category_title mb-2">{el.category}</div>
											{el.item.map((items, index: number) => {
												return (
													<div className="brand_details_menu_box d-flex" key={`brand_details_menu_item_${index}`}>
														<div className="brand_details_menu_image">
															<img src={`${items.itemImage}`} alt="img" />
														</div>
														<div className="brand_details_menu_details">
															<div className="brand_details_menu_details_title mb-2">{items.name}</div>
															<div className="brand_details_menu_details_description">
																<LinesEllipsis
																	text={items.description}
																	maxLine="6"
																	ellipsis="..."
																	trimRight
																	basedOn="letters"
																/>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									);
								})}
							</div>
							<div className="content_blur"></div>
						</div>
						<BrandKeyWords />
					</div>
					<div className="brand_theme_images">
						<img src={`${brandDetails.menuImage}`} alt="menuImage" />
					</div>
				</div>
				<>{isOnBoardingModalOpen ? <OnBoardingPage setIsOnBoardingModalOpen={setIsOnboardingModalOpen} /> : ""}</>
			</div>
			<Footer />
		</div>
	);
};
