import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../../utils/util.fns";
import { Config } from "../../config";
import axios from "axios";

export const getAllCities = createAsyncThunk("get/cities", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/states/city?${query}`, axiosConfig());
	return response.data;
});

export const getAllStates = createAsyncThunk("get/states", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/states?${query}`, axiosConfig());
	return response.data;
});

export const getAllCuisines = createAsyncThunk("get/cuisines", async () => {
	const response = await axios.get(Config.apiUrl + "/cuisines", axiosConfig());
	return response.data;
});

export const getAllCategories = createAsyncThunk("get/categories", async () => {
	const response = await axios.get(Config.apiUrl + "/categories", axiosConfig());
	return response.data;
});