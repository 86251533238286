import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { Brands } from "../../modal/brand-select";
import { createLocation } from "../../utils/util.fns";
import { EquipmentModal } from "../equipment-modal";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getEquipments } from "../../services/brand-select/brand-select.service";

type BrandListingPageProps = {
	addBrand: (el: Brands) => void;
};
const BrandListingPage: React.FunctionComponent<BrandListingPageProps> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const [showEquipmentModal, setShowEquipmentModal] = useState<boolean>(false);
	const developBrandList = useAppSelector((store) => store.brandSelect.brandList);
	const brandListLoading = useAppSelector((store) => store.brandSelect.brandListLoading);
	const addedBrands = useAppSelector((store) => store.brandSelect.addedBrands);
	const discountBrandList = useAppSelector((store) => store.brandSelect.discountList);

	const equipmentClicked = (id: number) => {
		dispatch(getEquipments(id));
		setShowEquipmentModal(true);
	};
	return (
		<>
			{!brandListLoading ? (
				<>
					{developBrandList.length ? (
						<div className="develop_brand_listing_container">
							{developBrandList &&
								developBrandList.map((el, i) => (
									<div className="develop_brand_listing_card d-flex mb-3" key={`develop_brand_listing_card_${i}`}>
										<div className="develop_brand_listing_card_image_container">
											<img src={el.thumbnailImage} />
										</div>
										<div className="develop_brand_listing_card_content d-flex justify-content-between flex-column">
											<div>
												<div className="develop_brand_listing_card_title mb-1">{el.name}</div>
												<div>
													{discountBrandList.some((d) => d.brandId === el.id) ? (
														<button className="primary_btn brand_select_active_btn small_btn m-1 cursor_auto">
															推薦
														</button>
													) : (
														""
													)}
													{el.keywords &&
														el.keywords.split(",").map((d, index) => (
															<button key={index} className="primary_border_btn small_btn m-1 cursor_auto">
																{d}
															</button>
														))}
												</div>
											</div>
											<div className="pt-1">
												<button
													className="primary_border_btn small_btn border_radius_2 mr-2"
													onClick={() =>
														navigate(
															createLocation(Locations.BRAND_DETAILS + `${location.search}`, {
																brandId: el.id.toString(),
															})
														)
													}
												>
													品牌詳情
												</button>
												<button
													className="primary_border_btn small_btn border_radius_2"
													onClick={() => equipmentClicked(el.id)}
												>
													器材
												</button>
											</div>
										</div>
										<div className="develop_brand_listing_card_toggles d-flex justify-content-between flex-column">
											<button
												className={
													addedBrands.find((brand) => brand.id === el.id)
														? "secondary_btn brand_list_btn"
														: "secondary_border_btn brand_list_btn"
												}
												onClick={() => props.addBrand(el)}
											>
												選擇品牌
											</button>

											<div className="develop_brand_listing_card_price">
												加盟金額 <span>{el.franchiseFees}</span>
											</div>
										</div>
									</div>
								))}
							{showEquipmentModal ? <EquipmentModal setShowEquipmentModal={setShowEquipmentModal} /> : ""}
						</div>
					) : (
						<div className="custom_no_data">未找到数据</div>
					)}
				</>
			) : (
				<div className="custom_no_data">正在加载...</div>
			)}
		</>
	);
};

export default BrandListingPage;
