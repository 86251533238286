import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";

export const getCuisineList = createAsyncThunk("cuisines/getAll", async () => {
	const response = await axios.get(Config.apiUrl + "/brand/cuisine", axiosConfig());
	return response.data;
});

export const getCategoryList = createAsyncThunk("category/getAll", async () => {
	const response = await axios.get(Config.apiUrl + "/brand/category", axiosConfig());
	return response.data;
});

export const getSelectedCuisineList = createAsyncThunk("selected/cuisines/getAll", async () => {
	const response = await axios.get(Config.apiUrl + "/selected-cuisine", axiosConfig());
	return response.data;
});

export const getSelectedCategoryList = createAsyncThunk("selected/category/getAll", async () => {
	const response = await axios.get(Config.apiUrl + "/selected-category", axiosConfig());
	return response.data;
});
