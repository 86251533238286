// export enum Config {
// 	apiUrl = "http://localhost:3000/api/v1",
// 	apiSecureUrl = "http://localhost:3000/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "en",
// }
// export enum Config {
// 	apiUrl = "http://192.168.2.54:8080/api/v1",
// 	apiSecureUrl = "http://192.168.2.54:8080/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "en",
// 	apiKeyClient = "AIzaSyBfiV71nLH2xfezfmQUFc4IVfR72oe0FVs",
// 	apiKeyLocal = "AIzaSyCSak6SeQ3JL5J99xZuob3z1sG2eHT7AQ0",
// }
// export enum Config {
// 	apiUrl = "http://192.168.2.54:8081/api/v1",
// 	apiSecureUrl = "http://192.168.2.54:8081/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "en",
// 	apiKeyClient = "AIzaSyBfiV71nLH2xfezfmQUFc4IVfR72oe0FVs",
// 	apiKeyLocal = "AIzaSyCSak6SeQ3JL5J99xZuob3z1sG2eHT7AQ0",
// }
//QA
export enum Config {
	apiUrl = "https://just-kitchen.simplogics.com/api/v1",
	apiSecureUrl = "https://just-kitchen.simplogics.com/api/v1/secure",
	env = "dev",
	defaultLanguage = "en",
	apiKeyClient = "AIzaSyBfiV71nLH2xfezfmQUFc4IVfR72oe0FVs",
	apiKeyLocal = "AIzaSyCSak6SeQ3JL5J99xZuob3z1sG2eHT7AQ0",
}

// Prod
// export enum Config {
// 	apiUrl = "/Foodfab/api/v1",
// 	apiSecureUrl = "/Foodfab/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "en",
// 	apiKeyClient = "AIzaSyBfiV71nLH2xfezfmQUFc4IVfR72oe0FVs",
// 	apiKeyLocal = "AIzaSyCSak6SeQ3JL5J99xZuob3z1sG2eHT7AQ0",
// }
