import { RootReducer } from "../modal/root-reducer";
import userReducer from "../services/user/user.slice";
import homepageReducer from "../services/homepage/homepage.slice";
import franchiseReducer from "../services/franchise-select/franchise-select-slice";
import advanceAnalysisReducer from "../services/advance-analysis/advance-analysis.slice";
import analyzeLocationReducer from "../services/analyze-location/analyze-location.slice";
import brandDetailsReducer from "../services/brand-details/brand-details.slice";
import brandSelectReducer from "../services/brand-select/brand-select.slice";
import predictionResultsReducer from "../services/prediction-results/prediction-results.slice";

export const rootReducer: RootReducer = {
	user: userReducer,
	homepage: homepageReducer,
	franchiseSelect: franchiseReducer,
	advanceAnalysis: advanceAnalysisReducer,
	analyzeLocation: analyzeLocationReducer,
	brandDetails: brandDetailsReducer,
	brandSelect: brandSelectReducer,
	predictionResults: predictionResultsReducer
};
