import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { BrandSelectReducer } from "../../modal/brand-select";
import { getBrandList, getDiscountList, getEquipments } from "./brand-select.service";

const initialState: BrandSelectReducer = {
	getBrandListComplete: null,
	brandList: [],
	brandListLoading: false,
	getDiscountListComplete: null,
	discountList: [],
	discountListLoading: false,
	addedBrands: [],
	persistBrand: false,
	getEquipmentsComplete: null,
	equipmentList: [],
};

const brandSelectSlice = createSlice({
	name: "brandSelect",
	initialState,
	reducers: {
		clearGetBrandList(state) {
			state.getBrandListComplete = null;
		},
		clearGetDiscountList(state) {
			state.getDiscountListComplete = null;
		},
		saveBrands(state, action) {
			state.addedBrands = action.payload.brandList;
			state.persistBrand = action.payload.persist;
		},
		clearGetEquipments(state) {
			state.getEquipmentsComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getBrandList.pending, (state, action) => {
			state.brandListLoading = true;
		});
		builder.addCase(getBrandList.fulfilled, (state, action) => {
			state.getBrandListComplete = APIStatus.FULFILLED;
			state.brandList = action.payload.data;
			state.brandListLoading = false;
		});
		builder.addCase(getBrandList.rejected, (state) => {
			state.getBrandListComplete = APIStatus.REJECTED;
			state.brandListLoading = false;
		});
		builder.addCase(getDiscountList.pending, (state, action) => {
			state.discountListLoading = true;
		});
		builder.addCase(getDiscountList.fulfilled, (state, action) => {
			state.getDiscountListComplete = APIStatus.FULFILLED;
			state.discountList = action.payload.data;
			state.discountListLoading = false;
		});
		builder.addCase(getDiscountList.rejected, (state) => {
			state.getDiscountListComplete = APIStatus.REJECTED;
			state.discountListLoading = false;
		});
		builder.addCase(getEquipments.fulfilled, (state, action) => {
			state.getEquipmentsComplete = APIStatus.FULFILLED;
			state.equipmentList = action.payload.data;
		});
		builder.addCase(getEquipments.rejected, (state) => {
			state.getEquipmentsComplete = APIStatus.REJECTED;
		});
	},
});

export const { clearGetBrandList, clearGetDiscountList, saveBrands, clearGetEquipments } = brandSelectSlice.actions;

export default brandSelectSlice.reducer;
