import React from "react";

export const MicroStoreSvg: React.FunctionComponent = () => {
	return (
		<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M54.0946 52.8438C51.6294 52.8438 49.623 54.8402 49.623 57.2973C49.623 57.9031 49.7454 58.479 49.9684 59.008C50.0645 59.2341 50.1869 59.4431 50.3137 59.6479C50.4579 59.8782 50.6196 60.0915 50.8032 60.2877C51.6206 61.1836 52.7877 61.7509 54.0946 61.7509C55.4016 61.7509 56.5686 61.1793 57.386 60.2877C57.5696 60.0915 57.7357 59.8782 57.8756 59.6479C58.0023 59.4431 58.1247 59.2341 58.2209 59.008C58.4394 58.4833 58.5662 57.9031 58.5662 57.2973C58.5706 54.8445 56.5643 52.8438 54.0946 52.8438ZM54.0946 55.6592C55.0038 55.6592 55.7425 56.393 55.7425 57.2973C55.7425 58.2017 55.0038 58.9354 54.0946 58.9354C53.1854 58.9354 52.4511 58.2017 52.4511 57.2973C52.4511 56.393 53.1898 55.6592 54.0946 55.6592Z"
				fill=""
			/>
			<path
				d="M11.9052 52.8438C9.4399 52.8438 7.43359 54.8402 7.43359 57.2973C7.43359 57.9031 7.55598 58.479 7.77891 59.008C7.8707 59.2341 7.99309 59.4431 8.12422 59.6479C8.26846 59.8782 8.43456 60.0915 8.61378 60.2877C9.43116 61.1836 10.5982 61.7509 11.9052 61.7509C13.2121 61.7509 14.3792 61.1793 15.1966 60.2877C15.3801 60.0915 15.5419 59.8782 15.6861 59.6479C15.8129 59.4431 15.9353 59.2341 16.0271 59.008C16.25 58.4833 16.3724 57.9031 16.3724 57.2973C16.3767 54.8445 14.3748 52.8438 11.9052 52.8438ZM11.9052 55.6592C12.8143 55.6592 13.5487 56.393 13.5487 57.2973C13.5487 58.2017 12.8143 58.9354 11.9052 58.9354C10.996 58.9354 10.2617 58.2017 10.2617 57.2973C10.2617 56.393 11.0004 55.6592 11.9052 55.6592Z"
				fill=""
			/>
			<path
				d="M60.6496 12.4661V4.25H5.35156V12.4661H17.7915V14.4071H5.35156V17.6406H10.2908H56.5583H60.6496V14.4071H48.4107V12.4661H60.6496Z"
				fill=""
			/>
			<path
				d="M65.1076 28.6704L61.3966 20.4117C61.1868 19.9467 60.7628 19.6523 60.2994 19.6523H5.65703C5.18933 19.6523 4.76534 19.9467 4.5599 20.4117L0.722125 28.9563C0.529799 29.3829 0.551654 29.8862 0.778948 30.2872C0.9975 30.6925 1.39964 30.9399 1.82363 30.9399H5.83624V43.7205V45.7511V54.2999C5.83624 55.0593 6.387 55.6693 7.06451 55.6693H58.892C59.5695 55.6693 60.1202 55.055 60.1202 54.2999V45.7511V43.7205V30.9399H64.1328C64.1503 30.9399 64.1634 30.9399 64.1809 30.9399C64.8584 30.9399 65.4092 30.3256 65.4092 29.5706C65.4092 29.225 65.2955 28.9136 65.1076 28.6704ZM57.6593 46.3057V47.3124V52.2353H8.29277V47.3124V46.3057V45.8193H57.6593V46.3057ZM8.29277 43.7376V31.0039H16.7551V43.7376H8.29277ZM57.6593 43.7376H49.197V31.0039H57.6593V43.7376ZM60.1202 28.3548H58.892H7.06451H5.83624H3.81245L6.42196 22.0114H59.5432L62.1484 28.3548H60.1202Z"
				fill=""
			/>
			<path
				d="M42.2906 14.9758H41.3858V14.5492H41.3727C41.1017 14.9204 40.669 15.0569 40.2712 15.0569C39.1697 15.0569 38.7676 14.1738 38.7676 13.2183C38.7676 12.2542 39.1697 11.401 40.2712 11.401C40.7564 11.401 41.0842 11.5972 41.3421 11.93H41.3552V11.3242H42.2863V14.9758H42.2906ZM41.3509 13.2183C41.3509 12.7277 41.1148 12.1049 40.516 12.1049C39.9084 12.1049 39.7423 12.749 39.7423 13.2183C39.7423 13.7003 39.9172 14.353 40.5291 14.353C41.1498 14.353 41.3509 13.7131 41.3509 13.2183Z"
				fill="white"
			/>
			<path
				d="M23.9411 11.4023C25.0077 11.4023 25.8207 12.102 25.8207 13.2537C25.8207 14.2562 25.13 15.0582 23.9411 15.0582C22.7566 15.0582 22.0703 14.2562 22.0703 13.2537C22.0703 12.102 22.8833 11.4023 23.9411 11.4023ZM23.9411 14.3543C24.658 14.3543 24.8459 13.7315 24.8459 13.1471C24.8459 12.6096 24.5531 12.1105 23.9411 12.1105C23.3335 12.1105 23.0451 12.6267 23.0451 13.1471C23.0451 13.723 23.233 14.3543 23.9411 14.3543Z"
				fill="white"
			/>
			<path
				d="M28.5232 11.4023C29.5897 11.4023 30.4027 12.102 30.4027 13.2537C30.4027 14.2562 29.7121 15.0582 28.5232 15.0582C27.3386 15.0582 26.6523 14.2562 26.6523 13.2537C26.6523 12.102 27.4654 11.4023 28.5232 11.4023ZM28.5232 14.3543C29.24 14.3543 29.428 13.7315 29.428 13.1471C29.428 12.6096 29.1351 12.1105 28.5232 12.1105C27.9156 12.1105 27.6271 12.6267 27.6271 13.1471C27.6271 13.723 27.815 14.3543 28.5232 14.3543Z"
				fill="white"
			/>
			<path
				d="M33.88 14.585H33.8669C33.5871 14.9262 33.2068 15.0585 32.7654 15.0585C31.6639 15.0585 31.2617 14.1754 31.2617 13.2199C31.2617 12.2558 31.6639 11.4026 32.7654 11.4026C33.2331 11.4026 33.5565 11.5604 33.8363 11.8889H33.8494V10.1484H34.7804V14.9774H33.8756V14.585H33.88ZM33.0276 14.3546C33.6483 14.3546 33.8494 13.719 33.8494 13.2199C33.8494 12.7293 33.6133 12.1065 33.0145 12.1065C32.4113 12.1065 32.2408 12.7506 32.2408 13.2199C32.2408 13.7019 32.4157 14.3546 33.0276 14.3546Z"
				fill="white"
			/>
			<path
				d="M43.4907 10.1484H44.4217V11.9316H44.4348C44.6927 11.5988 45.0206 11.4026 45.5058 11.4026C46.6073 11.4026 47.0094 12.2558 47.0094 13.2199C47.0094 14.1754 46.6073 15.0585 45.5058 15.0585C45.108 15.0585 44.6796 14.922 44.4042 14.5508H44.3911V14.9774H43.4863V10.1484H43.4907ZM45.2479 14.3546C45.8598 14.3546 46.0346 13.7019 46.0346 13.2199C46.0346 12.7506 45.8685 12.1065 45.261 12.1065C44.6621 12.1065 44.4261 12.7293 44.4261 13.2199C44.4305 13.7147 44.6315 14.3546 45.2479 14.3546Z"
				fill="white"
			/>
			<path
				d="M20.6099 11.0846C20.6099 10.8414 20.8635 10.4447 21.2175 10.3508L20.7454 9.71094C20.1291 9.78772 19.6789 10.3977 19.6789 11.0334V11.5197H18.9883V12.1852H19.6789V15.0604L20.6099 14.6252V12.1852H21.423V11.5197H20.6099V11.0846Z"
				fill="white"
			/>
			<path
				d="M37.3248 11.0494C37.3248 10.8062 37.5783 10.4095 37.9324 10.3157L37.4603 9.67578C36.844 9.75257 36.3938 10.3626 36.3938 10.9982V11.5186H35.7031V12.1841H36.3938V15.0593L37.3248 14.6242V12.1841H38.1378V11.5186H37.3248V11.0494Z"
				fill="white"
			/>
			<path d="M59.0377 47.6719H6.62891V49.647H59.0377V47.6719Z" fill="" />
		</svg>
	);
};
