import { Modal } from "antd";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { useAppSelector } from "../../modal/hooks";
import "./equipment-modal.scss";

type EquipmentModalProps = {
	setShowEquipmentModal(e: boolean): void;
};

export const EquipmentModal: React.FunctionComponent<EquipmentModalProps> = (props) => {
	const equipmentList = useAppSelector((store) => store.brandSelect.equipmentList);

	return (
		<Modal open={true} maskClosable={false} footer={[]} closable={false}>
			<div className="main_container equipment_modal d-flex flex-column">
				<div className="d-flex align-items-center justify-content-between py-1">
					<div className="main_title font_24 pl-1">設備清單</div>
					<div className="close_btn" onClick={() => props.setShowEquipmentModal(false)}>
						<img className="cursor-pointer" src="/images/close-btn.png" alt="img" />
					</div>
				</div>
				<div className="equipment_modal_content_container flex_1_1_10">
					<div className="equipment_list_container d-flex flex-wrap">
						{equipmentList.map((equipment, index) => (
							<div className="equipment_modal_box" key={index}>
								<LinesEllipsis
									className="card_sub_title"
									text={equipment.equipment}
									maxLine="2"
									ellipsis="..."
									trimRight
									basedOn="letters"
								/>
								<img src={equipment.equipmentImg} />
							</div>
						))}
					</div>
				</div>
				<div className="text-center pt-2">
					<button className="primary_border_btn min_width_150" onClick={() => props.setShowEquipmentModal(false)}>
						返回
					</button>
				</div>
			</div>
		</Modal>
	);
};
