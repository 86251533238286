import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";

type UserDetails = {
	email: string;
	password: string;
};

export const login = createAsyncThunk("user/login", async (user: UserDetails) => {
	const response = await axios.post(Config.apiUrl + "/signin", user);
	return response.data;
});

export const getCurrentUSerDetails = createAsyncThunk("user/details", async () => {
	const response = await axios.get(Config.apiSecureUrl + "/user/me", axiosConfig());
	return response.data;
});
