import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import "./header.scss";

type HeaderProps = {
	toggles: ReactNode;
};

export const Header: React.FunctionComponent<HeaderProps> = ({ toggles }) => {
	const navigate = useNavigate();
	return (
		<header className="custom_header">
			<div className="custom_header_content">
				<div className="custom_header_logo" onClick={() => navigate(Locations.BASE)}>
					<img className="cursor-pointer" src="/images/logo2.png" alt="logo" />
				</div>
				{toggles}
			</div>
		</header>
	);
};
