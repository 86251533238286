import React from "react";
import { useAppSelector } from "../../modal/hooks";
export const BrandKeyWords: React.FunctionComponent = () => {
	const brandDetailsKeyWords = useAppSelector((store) => store.brandDetails.brandDetailsKeyWords);

	return (
		<div className="flex_0_0_300 width_10 position-relative brand_key_words_box white_card_box overflow-hidden">
			<div className="brand_main_title mb-3">關鍵字</div>
			<div className="brand_key_words_list">
				{brandDetailsKeyWords.map((el, index) => (
					<span key={`brand_details_keywords_${index}`}> {el.keyword}</span>
				))}
			</div>
			<div className="content_blur"></div>
		</div>
	);
};
