import React from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { Modal } from "antd";
import "./onboarding.scss";
import { RightArrowSvg } from "../../assets/svg/right-arroe-svg";

type OnBoardingPageProps = {
	setIsOnBoardingModalOpen(e: boolean): void;
};

export const OnBoardingPage: React.FunctionComponent<OnBoardingPageProps> = (props) => {
	const navigate = useNavigate();

	return (
		<Modal
			className="onboarding_modal"
			open={true}
			onCancel={() => props.setIsOnBoardingModalOpen(false)}
			maskClosable={false}
			footer={[]}
		>
			<div className="onboarding_modal_container">
				<div className="onboarding_header_box pb-1">
					<div className="onboarding_header">嗨 ，從現在開始找準創業目標吧！</div>
					<div className="onboarding_sub_header">簡單3步驟 ，幫您找到最合適的加盟方案 ，獲得專屬市場預測 。</div>
				</div>
				<div className="joining_process d-flex align-items-center justify-content-center font_18 font_weight_bold pb-2">
					<div>
						<span>1</span>
						<span className="pl-3">加盟定位</span>
					</div>
					<span>
						<RightArrowSvg />
					</span>
					<div className="ml-3">
						<span>2</span>
						<span className="pl-3">制定品牌</span>
					</div>
					<span>
						<RightArrowSvg />
					</span>
					<div className="ml-3">
						<span>3</span>
						<span className="pl-3">預測結果</span>
					</div>
				</div>
				<div className="w-100 mt-1">
					<img className="onboarding_img" src="/images/onboarding.jpg" />
				</div>
				<div className="d-flex align-items-center pt-2">
					<button
						className="primary_btn onboarding_btn w-100 mx-1"
						onClick={() => navigate(Locations.FRANCHISE_POSITIONING + `?brandExists=${false}`)}
					>
						其他方案
					</button>
					<button
						className="secondary_btn onboarding_btn w-100 mx-1"
						onClick={() => navigate(Locations.FRANCHISE_POSITIONING + `?brandExists=${true}`)}
					>
						[加盟展限定] 超強品牌組合方案
					</button>
				</div>
			</div>
		</Modal>
	);
};
