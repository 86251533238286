import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import "./dark-header.scss";

type DarkHeaderProps = {
	subHeader?: ReactNode;
};

export const DarkHeader: React.FunctionComponent<DarkHeaderProps> = ({ subHeader }) => {
	const navigate = useNavigate();
	return (
		<>
			<div className="custom_dark_header">
				<div className="dark_header_logo" onClick={() => navigate(Locations.BASE)}>
					<img className="dark_header_logo_img" src="/images/logo.png" alt="logo" />
				</div>
			</div>
			{subHeader ? <div className="custom_sub_header">{subHeader}</div> : ""}
		</>
	);
};
