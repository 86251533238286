import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { BrandFilterList } from "../../modal/advance-analysis";
import { useAppSelector } from "../../modal/hooks";
import { showInfo } from "../../utils/util.fns";

type TabViewProps = {
	addCuisine: (el: BrandFilterList) => void;
	addCategory: (el: BrandFilterList) => void;
	removeCuisine: (el: BrandFilterList) => void;
	removeCategory: (el: BrandFilterList) => void;
	saveAnalysisData: () => void;
	selectedCuisine: BrandFilterList[];
	selectedCategory: BrandFilterList[];
};

export const TabView: React.FunctionComponent<TabViewProps> = (props) => {
	const cuisineList = useAppSelector((store) => store.advanceAnalysis.cuisineList);
	const categoryList = useAppSelector((store) => store.advanceAnalysis.categoryList);
	const cuisineListLoading = useAppSelector((store) => store.advanceAnalysis.cuisineListLoading);
	const categoryListLoading = useAppSelector((store) => store.advanceAnalysis.categoryListLoading);
	const navigate = useNavigate();
	const categorySelectedListLoading = useAppSelector((store) => store.advanceAnalysis.categorySelectedListLoading);
	const cuisineSelectedListLoading = useAppSelector((store) => store.advanceAnalysis.cuisineSelectedListLoading);
	const [showLocationModal, setshowLocationModal] = useState<boolean>(false);

	const showCategory = () => {
		if (!categoryListLoading) {
			return (
				<ul className="bttn-list">
					{categoryList.map((el, index) => (
						<li
							key={`categories_${index}`}
							onClick={() =>
								props.selectedCategory.length < 5 ? props.addCategory(el) : showInfo("Only 5 Categories are allowed")
							}
						>
							<a>{el.name}</a>
						</li>
					))}
				</ul>
			);
		}
	};

	const showCuisine = () => {
		if (!cuisineListLoading) {
			return (
				<ul className="bttn-list">
					{cuisineList.map((el, index) => (
						<li
							key={`cuisines_${index}`}
							onClick={() =>
								props.selectedCuisine.length < 2 ? props.addCuisine(el) : showInfo("Only 2 Cuisine are allowed")
							}
						>
							<a>{el.name}</a>
						</li>
					))}
				</ul>
			);
		}
	};

	const showSelectedCuisine = () => {
		if (!cuisineSelectedListLoading) {
			return (
				<ul className="bttn-list">
					{props.selectedCuisine.map((el, index) => (
						<div className="list" key={`selected_cuisines_${index}`}>
							<div className="bttn">
								{el.name}
								<a onClick={() => props.removeCuisine(el)}>
									<img src="/images/close.png" alt="img" />
								</a>
							</div>
						</div>
					))}
				</ul>
			);
		}
	};

	const showSelectedCategory = () => {
		if (!categorySelectedListLoading) {
			return (
				<ul className="bttn-list">
					{props.selectedCategory.map((el, index) => (
						<div className="list" key={`selected_categories_${index}`}>
							<div className="bttn">
								{el.name}
								<a onClick={() => props.removeCategory(el)}>
									<img src="/images/close.png" alt="img" />
								</a>
							</div>
						</div>
					))}
				</ul>
			);
		}
	};

	return (
		<div className="tab-content-div">
			<div className="tab-content" id="pills-tabContent">
				<div id="pills-second">
					<div className="tab-section">
						<h4>選擇您想經營的餐點種類</h4>
						<h5>參考店舖位置分析結果，更好的選擇合適類別！</h5>
						<div className="shadow-div cuisines">
							<h5>已選擇的菜系（最多2個）</h5>
							{showSelectedCuisine()}
							<span>{props.selectedCuisine.length}/2</span>
						</div>
						<div className="shadow-div category">
							<h5>已選擇的品類（最多5個）</h5>
							{showSelectedCategory()}
							<span>{props.selectedCategory.length}/5</span>
						</div>
						<div className="recomended-list">
							<h4>推薦分類</h4>

							<div className="single-list">
								<div className="head">
									<h5>原店鋪分類</h5>
									<span>
										<img src="/images/filled.png" alt="img"></img> 與原店鋪相同分類，讓您更快上手！
									</span>
								</div>
								<ul className="bttn-list">
									<li>
										<a>美式料理</a>
									</li>
									<li>
										<a>火鍋</a>
									</li>
									<li>
										<a>火鍋</a>
									</li>
								</ul>
							</div>
							<div className="single-list">
								<div className="head">
									<h5>綜合評分最高</h5>
									<span>
										<img src="/images/filled.png" alt="img"></img> 參考地區、趨勢等資料獲得最高評分
									</span>
								</div>
								<ul className="bttn-list">
									<li>
										<a>墨西哥料理</a>
									</li>
									<li>
										<a>火鍋</a>
									</li>
									<li>
										<a>火鍋</a>
									</li>
								</ul>
							</div>
							<div className="single-list">
								<div className="head">
									<h5>從位置分析結果推薦</h5>
									<span>
										<img src="/images/filled.png" alt="img"></img> 最適合此地區的餐飲分類
									</span>
								</div>
								<ul className="bttn-list">
									<li>
										<a>蛋糕</a>
									</li>
									<li>
										<a>火鍋</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="cuisine-list">
							<h4>所有菜系</h4>
							{showCuisine()}
						</div>
						<div className="category-list">
							<h4>所有品類</h4>
							{showCategory()}
						</div>
					</div>
					<div className="btn-outer">
						<a id="back-navigate" className="bttn no-bg" onClick={() => navigate(Locations.FRANCHISE_POSITIONING)}>
							重新輸入位置
						</a>
						<a id="navigate-to-step1" className="bttn orange-bg" onClick={() => setshowLocationModal(true)}>
							參考分析結果
						</a>
						<a className="bttn blue-bg" onClick={props.saveAnalysisData}>
							下一步：選擇加盟品牌
						</a>
					</div>
					{/* {showLocationModal ? <AnalyzeLocationModal /> : ""} */}
				</div>
			</div>
		</div>
	);
};
