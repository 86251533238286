import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import  cuisines  from "../../constants/franchise-select/getAllCuisines.json";
import  categories  from "../../constants/franchise-select/getAllCategories.json";
import { getAllCategories, getAllCities, getAllCuisines, getAllStates } from "./franchise-select.service";
import { CategoriesData, CitiesData, CuisinesData, StatesData } from "../../modal/franchise-select";
import _ from "lodash";

export interface FranchiseSelectReducer {
	getAllCitiesComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	cities: CitiesData[];
	totalCities: number;
	getAllStatesComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
    states: StatesData[];
	totalStates: number;
    getAllCuisinesComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
    cuisines: CuisinesData[];
    getAllCategoriesComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
    categories: CategoriesData[];

}

const initialState: FranchiseSelectReducer = {
	getAllCitiesComplete: null,
	cities: [],
	totalCities: 0,
	getAllStatesComplete: null,
	states: [],
	totalStates: 0,
	getAllCategoriesComplete: null,
	categories:[],
	getAllCuisinesComplete: null,
	cuisines: [],

};

const franchiseSelectSlice = createSlice({
	name: "franchiseSelect",
	initialState,
	reducers: {
		clearGetAllCities(state) {
			state.getAllCitiesComplete = null;
		},
		clearGetAllCitiesData(state) {
			state.cities = [];
		},
		clearGetAllStates(state) {
			state.getAllStatesComplete = null;
		},
		clearGetAllCuisines(state) {
			state.getAllCuisinesComplete = null;
		},
		clearGetAllCategories(state) {
			state.getAllCategoriesComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getAllCities.fulfilled, (state, action) => {
			state.getAllCitiesComplete = APIStatus.FULFILLED;
			state.cities = action.payload.data.data;
			state.cities = _.unionBy(state.cities, action.payload.data.data,"id");
			state.totalCities = action.payload.data.count;
		});
		builder.addCase(getAllCities.rejected, (state) => {
			state.getAllCitiesComplete = APIStatus.REJECTED;
		});
		builder.addCase(getAllStates.fulfilled, (state, action) => {
			state.getAllStatesComplete = APIStatus.FULFILLED;
			state.states = _.concat(state.states, action.payload.data.data);
			state.totalStates = action.payload.data.count;
		});
		builder.addCase(getAllStates.rejected, (state) => {
			state.getAllStatesComplete = APIStatus.REJECTED;
		});
		builder.addCase(getAllCuisines.fulfilled, (state, action) => {
			state.getAllCuisinesComplete = APIStatus.FULFILLED;
			state.cuisines = action.payload.data;
		});
		builder.addCase(getAllCuisines.rejected, (state) => {
			state.getAllCuisinesComplete = APIStatus.REJECTED;
			state.cuisines = cuisines;
		});
		builder.addCase(getAllCategories.fulfilled, (state, action) => {
			state.getAllCategoriesComplete = APIStatus.FULFILLED;
			state.categories = action.payload.data;
		});
		builder.addCase(getAllCategories.rejected, (state) => {
			state.getAllCategoriesComplete = APIStatus.REJECTED;
			state.categories = categories;
		});
	},
});

export const { clearGetAllCities, clearGetAllStates, clearGetAllCuisines, clearGetAllCategories, clearGetAllCitiesData } = franchiseSelectSlice.actions;

export default franchiseSelectSlice.reducer;