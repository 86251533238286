export enum Locations {
	BASE = "/",
	LOGIN = "/login",
	FORGOT_PASSWORD = "/forgot-password",
	RESET_PASSWORD = "/reset-password/:token",
	RESET_PASSWORD_URL = "/reset-password",
	DASHBOARD = "/dashboard",
	DEVELOP_BRAND = "/develop",
	FRANCHISE_POSITIONING = "/franchise-positioning",
	ADVANCE_ANALYSIS = "/advance-analysis",
	REGISTRATION_PAGE = "/registration-page",
	BRAND_DETAILS = "/brand-details/:brandId",
	BRAND_DETAIL = "/brand-details",
	PREDICTION_RESULTS = "/prediction-results",
}
