import React from "react";

export const RightArrowSvg: React.FunctionComponent = () => {
	return (
		<svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M27.9132 12.4995L1 12.4995"
				stroke="#FD8324"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0586 1.52667L27.9136 12.4988L17.0586 23.4727"
				stroke="#FD8324"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
