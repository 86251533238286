import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { sendFeedBack } from "../../services/prediction-results/prediction-result.service";
import { Invalid, SendFeedBackPayload } from "../../modal/prediction-results";
import { APIStatus, LocalStorageKeys, emailRegex, taiwanPhoneNumberRegex } from "../../constants";
import { TranslatedPlanType, PlanTypes } from "../../constants/franchise-select/planTypes";
import { clearSendFeedBack } from "../../services/prediction-results/prediction-results.slice";
import { Locations } from "../../constants/locations";
import { useNavigate } from "react-router-dom";
import "./contact-info-modal.scss";
import { Brands } from "../../modal/brand-select";
import { CloseIconSvg } from "../../assets/svg/close-icon-svg";

type ContactInfoConfirmationModalProps = {
	onClosedClicked(): void;
};

export const ContactInfoConfirmationModal: React.FunctionComponent<ContactInfoConfirmationModalProps> = (props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [contactInfoData, setContactInfoData] = useState<SendFeedBackPayload>({
		name: "",
		email: "",
		phoneNumber: "",
		remark: "",
		dateOfApplication: "",
		franchiseModel: "",
		shopAddress: "",
		brandsChosen: "",
	});
	const [invalid, setInvalid] = useState<Invalid>({
		name: false,
		email: false,
		phoneNumber: false,
	});
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
	const day = currentDate.getDate().toString().padStart(2, "0");
	const formattedDate = `${year}-${month}-${day}`;
	const predictionResultsReducer = useAppSelector((store) => store.predictionResults);
	const franchiseData = localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING);
	const predictionResults = franchiseData !== null ? JSON.parse(franchiseData) : null;

	useEffect(() => {
		if (predictionResultsReducer.sendFeedBackCompleted === APIStatus.FULFILLED) {
			navigate(Locations.BASE);
			dispatch(clearSendFeedBack());
		}
		if (predictionResultsReducer.sendFeedBackCompleted === APIStatus.REJECTED) {
			dispatch(clearSendFeedBack());
		}
	}, [predictionResultsReducer.sendFeedBackCompleted]);

	const isValid = (): boolean => {
		const prevState: Invalid = JSON.parse(JSON.stringify(invalid));
		if (!contactInfoData.name || !contactInfoData.name.trim()) {
			prevState.name = true;
		}
		if (!contactInfoData.email || !emailRegex.test(contactInfoData.email) || !contactInfoData.email.trim()) {
			prevState.email = true;
		}
		if (!contactInfoData.phoneNumber || !taiwanPhoneNumberRegex.test(contactInfoData.phoneNumber)) {
			prevState.phoneNumber = true;
		}
		setInvalid(prevState);
		return !(prevState.name || prevState.phoneNumber || prevState.email);
	};

	const sendFeedBackAsEmail = () => {
		const selectedBrands = localStorage.getItem(LocalStorageKeys.BRAND_SELECT);
		const selectedBrandsDatass = selectedBrands !== null ? JSON.parse(selectedBrands) : null;
		const brandNames: string[] = [];
		selectedBrandsDatass.map((el: Brands) => {
			if (el !== null) {
				brandNames.push(el.name);
			}
		});
		if (isValid()) {
			dispatch(
				sendFeedBack({
					...contactInfoData,
					dateOfApplication: formattedDate,
					franchiseModel: predictionResults.planType,
					shopAddress: predictionResults.address + ", " + predictionResults?.state + ", " + predictionResults?.cities,
					brandsChosen: brandNames.join(", "),
				})
			);
		}
	};

	return (
		<Modal className="contact_info_modal" open={true} footer={[]} closable={false}>
			<div className="main_container contact_info_modal_container">
				<div className="contact_info_header">
					<div className="custom_dark_header pb-2">
						<div className="dark_header_logo" onClick={() => navigate(Locations.BASE)}>
							<img className="dark_header_logo_img" src="/images/logo.png" alt="logo" />
						</div>
						<div className="main_title title_white text-center pt-1">
							輸入您的基本資訊 ，我們將把預測結果寄送至您的信箱並與您聯繫！
						</div>
						<div className="close_btn" onClick={props.onClosedClicked}>
							<CloseIconSvg />
						</div>
					</div>
					<div className="contact_info_date_box">
						<div className="d-flex">
							<div className="date_label">申請日期 ：</div>
							<div className="word_break">{formattedDate}</div>
						</div>
						<div className="d-flex">
							<div className="date_label">加盟模式 ：</div>
							<div className="word_break">
								{predictionResults.planType === PlanTypes.CLOUD_STORE
									? TranslatedPlanType.CLOUD_STORE
									: predictionResults.planType === PlanTypes.MICRO_STORE
										? TranslatedPlanType.MICRO_STORE
										: predictionResults.planType === PlanTypes.STANDARD_STORE
											? TranslatedPlanType.STANDARD_STORE
											: ""}
							</div>
						</div>
						<div className="d-flex">
							<div className="date_label">店鋪地址 ：</div>
							<div className="word_break">
								{predictionResults?.address ? `${predictionResults?.address}, ` : ""}
								{predictionResults.state ? `${predictionResults.state}, ` : ""}
								{predictionResults?.cities}
							</div>
						</div>
					</div>
				</div>
				<div className="contact_info_form_content flex_1_1_10">
					<div className="main_form_container d-flex py-2 px-2">
						<div className="w-50 pr-2">
							<div className="custom_input_field_container mb-2">
								<label className="custom_label contact_label">姓名*</label>
								<div className="custom_main_form">
									<div className="create_update_form_item">
										<input
											type="text"
											maxLength={100}
											value={contactInfoData.name}
											onChange={(e) => {
												setContactInfoData((prevState) => ({ ...prevState, name: e.target.value }));
												setInvalid((prevState) => ({ ...prevState, name: false }));
											}}
											placeholder="請輸入您的姓名"
										/>
										{invalid.name ? <span className="invalid_message invalid_top_minus_18">输入有效名称</span> : ""}
									</div>
								</div>
							</div>
							<div className="custom_input_field_container mb-2">
								<label className="custom_label contact_label">Email*</label>
								<div className="custom_main_form">
									<div className="create_update_form_item">
										<input
											type="email"
											maxLength={125}
											value={contactInfoData.email}
											onChange={(e) => {
												setContactInfoData((prevState) => ({ ...prevState, email: e.target.value }));
												setInvalid((prevState) => ({ ...prevState, email: false }));
											}}
											placeholder="請輸入您的電子郵件"
										/>
										{invalid.email ? (
											<span className="invalid_message invalid_top_minus_18">输入有效的电子邮件</span>
										) : (
											""
										)}
									</div>
								</div>
							</div>
							<div className="custom_input_field_container">
								<label className="custom_label contact_label">聯絡電話*</label>
								<div className="custom_main_form">
									<div className="create_update_form_item">
										<input
											type="tel"
											maxLength={125}
											value={contactInfoData.phoneNumber}
											onChange={(e) => {
												setContactInfoData((prevState) => ({ ...prevState, phoneNumber: e.target.value }));
												setInvalid((prevState) => ({ ...prevState, phoneNumber: false }));
											}}
											placeholder="請輸入您的聯絡電話"
										/>
										{invalid.phoneNumber ? (
											<span className="invalid_message invalid_top_minus_18">输入有效的电话号码</span>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="w-50 pl-2">
							<div className="custom_input_field_container mb-2">
								<label className="custom_label contact_label">備註</label>
								<div className="custom_main_form">
									<div className="create_update_form_item">
										<textarea
											className="custom_input_text_area"
											maxLength={250}
											value={contactInfoData.remark}
											placeholder="還有想了解的嗎？"
											onChange={(e) => {
												setContactInfoData((prevState) => ({ ...prevState, remark: e.target.value }));
											}}
										></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pt-2">
					<button className="secondary_btn login_btn w-100" onClick={sendFeedBackAsEmail}>
						寄預測結果到您的Email
					</button>
				</div>
			</div>
		</Modal>
	);
};
