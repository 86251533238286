import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";
import { SendFeedBackPayload } from "../../modal/prediction-results";

export const getPredictionResults = createAsyncThunk("prediction-results/getOne", async () => {
	const response = await axios.get(Config.apiUrl + "/prediction-results", axiosConfig());
	return response.data;
});

export const sendFeedBack = createAsyncThunk("prediction-results/feedback", async (payload: SendFeedBackPayload) => {
	const response = await axios.post(Config.apiUrl + "/feedback", payload, axiosConfig());
	return response.data;
});
