import React, { useEffect } from "react";
import { DevelopBrandFilterList } from "../../modal/brand-listing";
import { showInfo } from "../../utils/util.fns";
import AnalyzeLocationModal from "../analyze-location-modal";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getBrandList, getDiscountList } from "../../services/brand-select/brand-select.service";
import { Brands } from "../../modal/brand-select";
import { DarkHeader } from "../../components/dark-header";
import "./develop-brand.scss";
import { CloseSvg } from "../../assets/svg/close-svg";
import BrandListingPage from "../../components/brand-listing-page";
import { APIStatus, LocalStorageKeys } from "../../constants";
import { clearGetBrandList, clearGetDiscountList, saveBrands } from "../../services/brand-select/brand-select.slice";
import { PlanTypes } from "../../constants/franchise-select/planTypes";

export const DevelopBrand: React.FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const developBrandList = useAppSelector((store) => store.brandSelect.brandList);
	const getBrandListComplete = useAppSelector((store) => store.brandSelect.getBrandListComplete);
	const discountBrandList = useAppSelector((store) => store.brandSelect.discountList);
	const getDiscountListComplete = useAppSelector((store) => store.brandSelect.getDiscountListComplete);
	const addedBrands = useAppSelector((store) => store.brandSelect.addedBrands);
	const persistBrand = useAppSelector((store) => store.brandSelect.persistBrand);

	useEffect(() => {
		dispatch(getBrandList());
		dispatch(getDiscountList());
	}, []);

	useEffect(() => {
		if (getBrandListComplete === APIStatus.FULFILLED && getDiscountListComplete === APIStatus.FULFILLED) {
			if (
				(params.get("planType") === PlanTypes.MICRO_STORE || params.get("planType") === PlanTypes.STANDARD_STORE) &&
				!persistBrand
			) {
				const discounted = developBrandList.filter((el) => discountBrandList.some((d) => d.brandId === el.id));
				dispatch(saveBrands({ brandList: discounted, persist: true }));
			}
			if (params.get("planType") === PlanTypes.CLOUD_STORE && !persistBrand) {
				const discounted = developBrandList.filter((el) => discountBrandList.some((d) => d.brandId === el.id));
				dispatch(saveBrands({ brandList: discounted.slice(0, 1), persist: true }));
			}
			localStorage.setItem(LocalStorageKeys.DISCOUNT, JSON.stringify(discountBrandList));
			dispatch(clearGetBrandList());
			dispatch(clearGetDiscountList());
		}
		if (getBrandListComplete === APIStatus.REJECTED && getDiscountListComplete === APIStatus.REJECTED) {
			dispatch(clearGetBrandList());
			dispatch(clearGetDiscountList());
		}
	}, [getBrandListComplete, getDiscountListComplete, params.get("addedBrands")]);

	const addBrand = (el: Brands) => {
		const isBrandAdded = addedBrands.find((brand) => brand.id === el.id);
		if (!isBrandAdded) {
			dispatch(saveBrands({ brandList: [...addedBrands, el], persist: true }));
		} else {
			showInfo("已添加");
		}
	};

	const removeBrand = (el: DevelopBrandFilterList) => {
		const updatedCategoryList = [...addedBrands];
		const filteredBrand = updatedCategoryList.filter((item) => item.id !== el.id);
		dispatch(saveBrands({ brandList: filteredBrand, persist: true }));
	};

	return (
		<div className="main_container d-flex flex-column">
			<DarkHeader
				subHeader={[
					<div key={"develop_brand_sub_header_items"} className="container">
						<div className="margin_x_neg_5 d-flex align-items-center justify-content-center flex-wrap">
							<div className="custom_pill">1 加盟定位</div>
							<div className="flex_1_1_10 custom_pill custom_pill_filled">2 制定品牌</div>
							<div className="custom_pill">3 預測結果</div>
						</div>
					</div>,
				]}
			/>
			<div className="brand_banner_image">
				<img className="" src="/images/develop-brand/develop_banner.jpg" />
			</div>
			<div className="d-flex flex-column container develop_brand_container flex_1_1_10">
				<div className="main_title mt-3 mb-2">選擇您想加盟的品牌</div>
				<div className="develop_brand_filter_container d-flex flex-wrap align-items-center mb-3">
					<div className="develop_brand_filter_label mr-2">已選擇品牌</div>
					{addedBrands.map((el, index) => (
						<div className="develop_brand_filter_box" key={`selected_develop_brand_${index}`}>
							{el.name}
							<span className="develop_brand_filter_close" onClick={() => removeBrand(el)}>
								<CloseSvg />
							</span>
						</div>
					))}
				</div>
				<BrandListingPage addBrand={addBrand} />
			</div>
			<AnalyzeLocationModal />
		</div>
	);
};
