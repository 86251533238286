import React, { useEffect, useState } from "react";
import { TabView } from "../../components/tab-view";
import { APIStatus, LocalStorageKeys } from "../../constants";
import { BrandFilterList } from "../../modal/advance-analysis";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import {
	getCategoryList,
	getCuisineList,
	getSelectedCategoryList,
	getSelectedCuisineList,
} from "../../services/advance-analysis/advance-analysis.service";
import categoryList from "../../constants/franchise-select/getAllCategories.json";
import cuisineList from "../../constants/franchise-select/getAllCuisines.json";
import {
	clearGetAllSelectedCategoryList,
	clearGetAllSelectedCuisineList,
} from "../../services/advance-analysis/advance-analysis.slice";
import { DarkHeader } from "../../components/dark-header";

export const AdvanceAnalysis: React.FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const [selectedCuisine, setSelectedCuisine] = useState<BrandFilterList[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<BrandFilterList[]>([]);
	const getSelectedCuisineListCompleted = useAppSelector(
		(store) => store.advanceAnalysis.getSelectedCuisineListCompleted
	);
	const getSelectedCategoryListCompleted = useAppSelector(
		(store) => store.advanceAnalysis.getSelectedCategoryListCompleted
	);
	const cuisineSelectedList = useAppSelector((store) => store.advanceAnalysis.cuisineSelectedList);
	const categorySelectedList = useAppSelector((store) => store.advanceAnalysis.categorySelectedList);

	useEffect(() => {
		localStorage.removeItem(LocalStorageKeys.ADVANCED_ANALYSIS);
		dispatch(getCuisineList());
		dispatch(getCategoryList());
		dispatch(getSelectedCuisineList());
		dispatch(getSelectedCategoryList());
	}, []);

	useEffect(() => {
		if (getSelectedCuisineListCompleted === APIStatus.FULFILLED) {
			setSelectedCuisine(cuisineSelectedList);
			dispatch(clearGetAllSelectedCuisineList());
		}
		if (getSelectedCuisineListCompleted === APIStatus.REJECTED) {
			const franchiseData = localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING);
			if (franchiseData !== null) {
				const storedData = JSON.parse(franchiseData);
				const cuisineSelected = storedData.cuisine;
				const prevCuisine = cuisineList.filter((el) => el.id === cuisineSelected);
				setSelectedCuisine(prevCuisine);
			} //remove after API integration
			dispatch(clearGetAllSelectedCuisineList());
		}
	}, [getSelectedCuisineListCompleted]);

	useEffect(() => {
		if (getSelectedCategoryListCompleted === APIStatus.FULFILLED) {
			setSelectedCuisine(categorySelectedList);
			dispatch(clearGetAllSelectedCategoryList());
		}
		if (getSelectedCategoryListCompleted === APIStatus.REJECTED) {
			if (localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING)) {
				const storedData =
					localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING) &&
					JSON.parse(localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING) ?? "");
				const categorySelected = storedData.category;
				const prevCategory = categoryList.filter((el) => el.id === categorySelected);
				setSelectedCategory(prevCategory);
			} //remove after API integration
			dispatch(clearGetAllSelectedCategoryList());
		}
	}, [getSelectedCategoryListCompleted]);

	const addCuisine = (el: BrandFilterList) => {
		setSelectedCuisine((prevState) => {
			return [...prevState, el];
		});
	};

	const addCategory = (el: BrandFilterList) => {
		setSelectedCategory((prevState) => {
			return [...prevState, el];
		});
	};

	const removeCuisine = (el: BrandFilterList) => {
		const updatedCuisineList = [...selectedCuisine];
		const filteredCuisine = updatedCuisineList.filter((item) => item.id !== el.id);
		setSelectedCuisine(filteredCuisine);
	};

	const removeCategory = (el: BrandFilterList) => {
		const updatedCategoryList = [...selectedCategory];
		const filteredCategory = updatedCategoryList.filter((item) => item.id !== el.id);
		setSelectedCategory(filteredCategory);
	};

	const saveAnalysisData = () => {
		const savedData = {
			cuisineData: selectedCuisine,
			categoryData: selectedCategory,
		};
		localStorage.setItem(LocalStorageKeys.ADVANCED_ANALYSIS, JSON.stringify(savedData));
	};

	return (
		<div className="dark-bg">
			<div className="main-outercon">
				<DarkHeader />
				<section className="tab-container franchise">
					<div className="head-top">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<ul className="nav nav-pills nav-tabs-dropdown" id="pills-tab">
										<li className="nav-item" role="presentation">
											<h3>
												<strong>1 </strong>加盟定位
											</h3>
										</li>
										<li className="nav-item active-item" role="presentation">
											<h3>
												<strong>2</strong> 進階分析
											</h3>
										</li>
										<li className="nav-item" role="presentation">
											<h3>
												<strong>3</strong> 制定品牌
											</h3>
										</li>
										<li className="nav-item" role="presentation">
											<h3>
												<strong>4</strong> 制定菜單
											</h3>
										</li>
										<li className="nav-item" role="presentation">
											<h3>
												<strong>5</strong> 預測結果
											</h3>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<TabView
						addCuisine={addCuisine}
						addCategory={addCategory}
						removeCuisine={removeCuisine}
						removeCategory={removeCategory}
						saveAnalysisData={saveAnalysisData}
						selectedCuisine={selectedCuisine}
						selectedCategory={selectedCategory}
					/>
				</section>
			</div>
			<footer className="footer-container">
				<div className="footer-top">
					<div className="container">
						<div className="row ">
							<div className="col-md-12"></div>
						</div>
					</div>
				</div>
			</footer>

			<div className="mobile-visible"></div>
		</div>
	);
};
