import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { StatesData } from "../../modal/franchise-select";
import { Pagination } from "../../modal/common";
import { getAllStates } from "../../services/franchise-select/franchise-select.service";

type StateDropDownProps = {
	value: string | null;
	onChange(e: string | null): void;
};

const StateDropDown: React.FunctionComponent<StateDropDownProps> = (props) => {
	const Option = Select;
	const states = useAppSelector((store) => store.franchiseSelect.states);
	const [pagination, setPagination] = useState<Pagination>({
		offset: 0,
		numResults: 50,
	});
	const dispatch = useAppDispatch();
	const franchiseSelectReducer = useAppSelector((store) => store.franchiseSelect);

	useEffect(() => {
		const query = `offset=${pagination.offset}&numResults=${pagination.numResults}`;
		dispatch(getAllStates(query));
	}, [pagination.offset]);

	const scrollChanged = () => {
		if (franchiseSelectReducer.states.length < franchiseSelectReducer.totalStates) {
			setPagination((prevState) => ({ ...prevState, offset: prevState.offset + 1 }));
		}
	};

	return (
		<Select
			className={props.value ? "custom_select data_select" : "custom_select"}
			placeholder="地區"
			value={props.value}
			onChange={(value) => props.onChange(value)}
			onPopupScroll={scrollChanged}
		>
			{states.map((el: StatesData, i: number) => {
				return <Option key={`state_${i}`} value={el} />;
			})}
		</Select>
	);
};

export default StateDropDown;
