import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home-page.scss";
import { HomepageContent } from "../../components/homepage-content";
import { LocalStorageKeys } from "../../constants";

export const Homepage: React.FunctionComponent = () => {
	useEffect(() => {
		localStorage.removeItem(LocalStorageKeys.ADVANCED_ANALYSIS);
		localStorage.removeItem(LocalStorageKeys.FRANCHISE_POSITIONING);
		localStorage.removeItem(LocalStorageKeys.ANALYZE_LOCATION);
		localStorage.removeItem(LocalStorageKeys.BRAND_SELECT);
	}, []);

	return (
		<div className="main-outercon overflow-auto h-100">
			<div className="wrap w-100 h-100">
				<HomepageContent />
			</div>
		</div>
	);
};
