import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";

export const getAllNumerousCategories = createAsyncThunk("numerous/categories", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/report/categoryCount?${query}`, axiosConfig());
	return response.data;
});

export const getAllMostPopularCuisine = createAsyncThunk("popular/cuisine", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/report/cuisineCount?${query}`, axiosConfig());
	return response.data;
});

export const getAllShopLocation = createAsyncThunk("shop/location", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/fpshop/location?${query}`, axiosConfig());
	return response.data;
});

export const getAllShopCount = createAsyncThunk("shop/count", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/fpshop/location/count?${query}`, axiosConfig());
	return response.data;
});

export const getAllShopRating = createAsyncThunk("/fpshop/rating", async (query: string) => {
	const response = await axios.get(Config.apiUrl + `/fpshop/rating?${query}`, axiosConfig());
	return response.data;
});