import React, { useEffect, useState } from "react";
import { DarkHeader } from "../../components/dark-header";
import "./prediction-result.scss";
import CountUp from "react-countup";
import { useLocation, useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { ContactInfoConfirmationModal } from "../../components/contact-info-modal";
import { LocalStorageKeys } from "../../constants";
import { Brands } from "../../modal/brand-select";
import { PlanTypes } from "../../constants/franchise-select/planTypes";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";

export const PredictionResults: React.FunctionComponent = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
	const brandData = localStorage.getItem(LocalStorageKeys.BRAND_SELECT);
	const discountData = localStorage.getItem(LocalStorageKeys.DISCOUNT);
	const franchiseBrandData = localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING);
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const grossProfit = (): number => {
		if (params.get("planType") === PlanTypes.CLOUD_STORE) {
			return 30;
		}
		if (params.get("planType") === PlanTypes.MICRO_STORE) {
			return 55;
		}
		if (params.get("planType") === PlanTypes.STANDARD_STORE) {
			return 55;
		}
		return 0;
	};

	const fundSpeed = () => {
		if (params.get("planType") === PlanTypes.CLOUD_STORE) {
			return <CountUp end={6} />;
		} else if (params.get("planType") === PlanTypes.MICRO_STORE) {
			return (
				<>
					<CountUp end={8} /> - <CountUp end={10} />
				</>
			);
		} else if (params.get("planType") === PlanTypes.STANDARD_STORE) {
			return (
				<>
					<CountUp end={12} /> - <CountUp end={15} />
				</>
			);
		}
	};

	const brandPrice = () => {
		let totalPrice = 0;
		if (brandData !== null && discountData !== null) {
			const discountBrandData = JSON.parse(discountData);
			const storedData = JSON.parse(brandData);
			Object.values(storedData).forEach((brand: any) => {
				if (params.get("planType") === PlanTypes.MICRO_STORE || params.get("planType") === PlanTypes.STANDARD_STORE) {
					if (discountBrandData.findIndex((el: any) => el.brandId === brand.id) === -1) {
						totalPrice += brand.franchiseFees;
					}
				} else {
					totalPrice += brand.franchiseFees;
				}
			});
		}
		return totalPrice;
	};

	const monthlyRevenueSum = () => {
		if (brandData !== null && franchiseBrandData !== null) {
			const storedData = JSON.parse(brandData);
			const franchiseData = JSON.parse(franchiseBrandData);
			let totalPrice = 0;
			Object.values(storedData).forEach((brand: any) => {
				totalPrice += brand.monthlyRevenue;
			});
			if (params.get("planType") === PlanTypes.CLOUD_STORE) {
				totalPrice += 30000;
			}
			if (params.get("planType") === PlanTypes.MICRO_STORE) {
				totalPrice += 100000;
			}
			if (params.get("planType") === PlanTypes.STANDARD_STORE) {
				totalPrice += 300000;
			}
			totalPrice *= franchiseData.revenueMultiple;
			return totalPrice;
		}
		return 0;
	};

	const priceInMillion = () => {
		if (params.get("planType") === PlanTypes.CLOUD_STORE) {
			return "0";
		}
		if (params.get("planType") === PlanTypes.MICRO_STORE) {
			return "29萬加盟金 包含3個品牌";
		}
		if (params.get("planType") === PlanTypes.STANDARD_STORE) {
			return "99萬加盟金. 包含三個品牌+開店費用（約12坪）";
		}
		return "0";
	};

	const showBrands = () => {
		const storedData = JSON.parse(brandData ?? "");
		if (storedData && storedData.length) {
			return storedData.map((el: Brands, i: number) => (
				<div className="result_brand_list_card" key={`result_brand_list_container_${i}`}>
					<div className="result_brand_list_card_image_container">
						<img src={el.thumbnailImage} alt="img" />
					</div>
					<div className="result_brand_list_card_title ellipsis mb-1">{el.name}</div>
					<div className="result_brand_list_card_description ellipsis mb-2">{el.keywords}</div>
					<div className="result_brand_list_card_toggles">
						<button className="secondary_btn mr-2 border_radius_4 font_12 cursor_auto">{el.brandClass}</button>
						<button className="primary_btn border_radius_4 font_12 cursor_auto">{el.type}</button>
					</div>
				</div>
			));
		}
	};

	const showFranchiseBrands = () => {
		if (brandData !== null) {
			const storedData = JSON.parse(brandData);
			const brandNames: string[] = [];
			Object.values(storedData).forEach((brand: any) => {
				brandNames.push(brand.name);
			});
			return brandNames.join(", ");
		}
		return "";
	};

	const onClosedClicked = () => {
		setIsContactModalOpen(false);
	};

	return (
		<div className="main_container">
			<DarkHeader
				subHeader={[
					<div key={"develop_brand_sub_header_items"} className="container">
						<div className="margin_x_neg_5 d-flex align-items-center justify-content-center flex-wrap">
							<div className="custom_pill">1 加盟定位</div>
							<div className="custom_pill">2 制定品牌</div>
							<div className="custom_pill flex_1_1_10 custom_pill_filled">3 預測結果</div>
						</div>
					</div>,
				]}
			/>
			<div className="container prediction_result_container mb-2">
				<div className="main_title text-center my-3">成功預測！</div>
				<div className="result_card_container mb-3">
					<div className="d-flex justify-content-center">
						<div className="result_count_box mr-3">
							<div className="result_count">
								<CountUp end={monthlyRevenueSum()} />
							</div>
							<div className="result_title">月平均營收</div>
						</div>
						<div className="result_count_box mr-3">
							<div className="result_count">
								<CountUp end={grossProfit()} />
								{params.get("planType") === PlanTypes.CLOUD_STORE ? "% (已扣除外送抽成)" : "%"}
							</div>
							<div className="result_title">平均毛利</div>
						</div>
						<div className="result_count_box">
							<div className="result_count">{fundSpeed()}個月</div>
							<div className="result_title">資金回收速度</div>
						</div>
					</div>
					<div className="pt-3">
						<div>加盟品牌：</div>
						<div className="color_theme font_24 font_weight_bold">{showFranchiseBrands()}</div>
					</div>
					<div className="pt-3">
						<div>加盟金額：</div>
						<div className="color_theme font_24 font_weight_bold">NT {brandPrice()}</div>
					</div>
					<div className="pt-3">
						<div>加盟總金額：</div>
						<div className="color_theme font_24 font_weight_bold">{priceInMillion()}</div>
					</div>
					<div className="d-flex pt-3">
						<div className="flex_1_1_10 width_10">
							<div>經營人數：</div>
							<div className="color_theme font_24 font_weight_bold">1-2人</div>
						</div>
						<div className="flex_1_1_10 width_10">
							<div>店面坪數：</div>
							<div className="color_theme font_24 font_weight_bold">2-3坪</div>
						</div>
						<div className="flex_1_1_10 width_10">
							<div>座位數：</div>
							<div className="color_theme font_24 font_weight_bold">3-10個</div>
						</div>
						<div className="flex_0_0_150 width_10">
							<div>銷售模式：</div>
							<div className="color_theme font_24 font_weight_black">
								<button className="prediction_keyword_btn primary_border_btn small_btn mr-2 font_weight_black border_2_btn cursor_auto">
									內用
								</button>
								<button className="prediction_keyword_btn primary_border_btn small_btn font_weight_black border_2_btn cursor_auto">
									外送
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="result_brand_list_container d-flex flex-wrap">{showBrands()}</div>
			</div>
			<div className="basic_footer_container d-flex align-items-center justify-content-center">
				<button onClick={() => navigate(Locations.BASE)} className="primary_border_btn mr-2">
					返回首頁
				</button>
				<button onClick={() => setIsContactModalOpen(true)} className="secondary_btn d-flex align-items-center">
					<svg
						className="mr-2"
						width="28"
						height="30"
						viewBox="0 0 38 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M13.4871 3.7503C13.4669 3.7499 13.4467 3.7499 13.4266 3.7503H10.3744C8.4154 3.7503 6.65737 4.98456 5.94735 6.85837L3.11644 14.3294C2.42866 16.1445 3.1843 18.1352 4.75004 19.045V31.3753C4.75004 34.0676 6.87669 36.2503 9.50004 36.2503H28.5C31.1234 36.2503 33.25 34.0676 33.25 31.3753V19.045C34.8158 18.1352 35.5714 16.1445 34.8837 14.3294L32.0528 6.85837C31.3427 4.98456 29.5847 3.7503 27.6257 3.7503H24.5735C24.5534 3.7499 24.5333 3.7499 24.5131 3.7503H13.4871ZM30.0834 19.5753C29.3732 19.4899 28.6772 19.2787 28.0275 18.9453L26.9174 18.3757L26.9158 18.3757L25.8986 18.7933C24.0111 19.5681 21.9056 19.5681 20.0182 18.7933L19 18.3753L17.9819 18.7933C16.0945 19.5681 13.989 19.5681 12.1015 18.7933L11.0843 18.3757L11.0827 18.3757L9.97257 18.9453C9.32293 19.2787 8.62689 19.4899 7.91671 19.5753V31.3753C7.91671 32.2727 8.62559 33.0003 9.50004 33.0003H12.6667V28.1253C12.6667 25.4329 14.7934 23.2503 17.4167 23.2503H20.5834C23.2067 23.2503 25.3334 25.4329 25.3334 28.1253V33.0003H28.5C29.3745 33.0003 30.0834 32.2727 30.0834 31.3753V19.5753ZM28.3329 15.4684C28.309 15.4562 28.285 15.4442 28.2609 15.4326L26.5003 7.0003H27.6257C28.2787 7.0003 28.8647 7.41172 29.1014 8.03632L31.9323 15.5074C32.0548 15.8307 31.8732 16.191 31.5452 16.2752C30.8396 16.4562 30.0942 16.3723 29.4437 16.0384L28.3329 15.4684ZM25.0646 15.6353L23.2617 7.0003H20.5834V15.5249L21.1943 15.7757C22.3267 16.2406 23.59 16.2406 24.7225 15.7757L25.0646 15.6353ZM17.4167 7.0003H14.7384L12.9355 15.6353L13.2776 15.7757C14.4101 16.2406 15.6734 16.2406 16.8058 15.7757L17.4167 15.5249V7.0003ZM9.73927 15.4326L11.4999 7.0003H10.3744C9.72138 7.0003 9.13537 7.41172 8.89869 8.03632L6.06778 15.5074C5.94526 15.8307 6.12691 16.191 6.45486 16.2752C7.16045 16.4562 7.90587 16.3723 8.55639 16.0384L9.66721 15.4684C9.69111 15.4561 9.71514 15.4442 9.73927 15.4326ZM22.1667 28.1253V33.0003H15.8334V28.1253C15.8334 27.2278 16.5423 26.5003 17.4167 26.5003H20.5834C21.4578 26.5003 22.1667 27.2278 22.1667 28.1253Z"
							fill="white"
						/>
					</svg>
					立即申請＆付訂開始創業
				</button>
			</div>
			{isContactModalOpen ? (
				<div>
					<ContactInfoConfirmationModal onClosedClicked={onClosedClicked} />
				</div>
			) : (
				""
			)}
		</div>
	);
};
