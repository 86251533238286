import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { ErrorBoundary } from "../plugins/error-boundary";
import { Locations } from "../constants/locations";
import { Homepage } from "../containers/homepage";
import { DevelopBrand } from "../containers/develop-brand";
import { FranchisePositioning } from "../containers/franchise-positioning";
import { AdvanceAnalysis } from "../containers/advance-analysis";
import { Login } from "../containers/login-page";
import { RegistrationPage } from "../containers/registration-page";
import { BrandDetails } from "../containers/brand-details";
import { PredictionResults } from "../containers/prediction-results";

export const router = createBrowserRouter([
	{
		path: Locations.LOGIN,
		element: <Login />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.BASE,
		element: <Homepage />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.DEVELOP_BRAND,
		element: <DevelopBrand />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.FRANCHISE_POSITIONING,
		element: <FranchisePositioning />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.ADVANCE_ANALYSIS,
		element: <AdvanceAnalysis />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.REGISTRATION_PAGE,
		element: <RegistrationPage />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.BRAND_DETAILS,
		element: <BrandDetails />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.PREDICTION_RESULTS,
		element: <PredictionResults />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: "*",
		element: <Navigate to={Locations.BASE} />,
		errorElement: <ErrorBoundary />,
	},
]);
