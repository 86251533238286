import { createSlice } from "@reduxjs/toolkit";
import { APIStatus, LocalStorageKeys } from "../../constants";
import { PredictionResultsData, PredictionResultsReducer } from "../../modal/prediction-results";
import { getPredictionResults, sendFeedBack } from "./prediction-result.service";

const initialState: PredictionResultsReducer = {
	getPredictionResultsCompleted: null,
	predictionResults: {} as PredictionResultsData,
	sendFeedBackCompleted: null
};

const predictionResultsSlice = createSlice({
	name: "predictionResults",
	initialState,
	reducers: {
		clearGetPredictionResults(state) {
			state.getPredictionResultsCompleted = null;
		},
		clearSendFeedBack(state) {
			state.sendFeedBackCompleted = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getPredictionResults.fulfilled, (state, action) => {
			state.getPredictionResultsCompleted = APIStatus.FULFILLED;
			state.predictionResults = action.payload.data;
		});
		builder.addCase(getPredictionResults.rejected, (state) => {
			state.getPredictionResultsCompleted = APIStatus.REJECTED;
			const franchiseData = localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING);
			state.predictionResults =  franchiseData !== null ? JSON.parse(franchiseData ) : null;
		});
		builder.addCase(sendFeedBack.fulfilled, (state) => {
			state.sendFeedBackCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(sendFeedBack.rejected, (state) => {
			state.sendFeedBackCompleted = APIStatus.REJECTED;
		});
	},
});
export const { clearGetPredictionResults, clearSendFeedBack } = predictionResultsSlice.actions;

export default predictionResultsSlice.reducer;
