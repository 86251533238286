import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import categoryList from "../../constants/franchise-select/getAllCategories.json";
import cuisineList from "../../constants/franchise-select/getAllCuisines.json";
import { AdvanceAnalysisReducer } from "../../modal/advance-analysis";
import {
	getCategoryList,
	getCuisineList,
	getSelectedCategoryList,
	getSelectedCuisineList,
} from "./advance-analysis.service";

const initialState: AdvanceAnalysisReducer = {
	getCuisineListCompleted: null,
	cuisineListLoading: false,
	cuisineList: [],
	getCategoryListCompleted: null,
	categoryListLoading: false,
	categoryList: [],
	getSelectedCuisineListCompleted: null,
	cuisineSelectedListLoading: false,
	cuisineSelectedList: [],
	getSelectedCategoryListCompleted: null,
	categorySelectedListLoading: false,
	categorySelectedList: [],
};

const advanceAnalysisSlice = createSlice({
	name: "advanceAnalysis",
	initialState,
	reducers: {
		clearGetAllCuisineList(state) {
			state.getCuisineListCompleted = null;
		},
		clearGetAllCategoryList(state) {
			state.getCuisineListCompleted = null;
		},
		clearGetAllSelectedCuisineList(state) {
			state.getSelectedCuisineListCompleted = null;
		},
		clearGetAllSelectedCategoryList(state) {
			state.getSelectedCategoryListCompleted = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getCuisineList.pending, (state) => {
			state.cuisineListLoading = true;
		});
		builder.addCase(getCuisineList.fulfilled, (state, action) => {
			state.getCuisineListCompleted = APIStatus.FULFILLED;
			state.cuisineListLoading = false;
			state.cuisineList = action.payload.data;
		});
		builder.addCase(getCuisineList.rejected, (state) => {
			state.getCuisineListCompleted = APIStatus.REJECTED;
			state.cuisineListLoading = false;
			state.cuisineList = cuisineList;
		});
		builder.addCase(getCategoryList.pending, (state) => {
			state.categoryListLoading = true;
		});
		builder.addCase(getCategoryList.fulfilled, (state, action) => {
			state.getCategoryListCompleted = APIStatus.FULFILLED;
			state.categoryListLoading = false;
			state.categoryList = action.payload.data;
		});
		builder.addCase(getCategoryList.rejected, (state) => {
			state.getCategoryListCompleted = APIStatus.REJECTED;
			state.categoryListLoading = false;
			state.categoryList = categoryList;
		});
		builder.addCase(getSelectedCuisineList.pending, (state) => {
			state.cuisineSelectedListLoading = true;
		});
		builder.addCase(getSelectedCuisineList.fulfilled, (state, action) => {
			state.getSelectedCuisineListCompleted = APIStatus.FULFILLED;
			state.cuisineSelectedListLoading = false;
			state.cuisineSelectedList = action.payload.data;
		});
		builder.addCase(getSelectedCuisineList.rejected, (state) => {
			state.getSelectedCuisineListCompleted = APIStatus.REJECTED;
			state.cuisineSelectedListLoading = false;
		});
		builder.addCase(getSelectedCategoryList.pending, (state) => {
			state.categorySelectedListLoading = true;
		});
		builder.addCase(getSelectedCategoryList.fulfilled, (state, action) => {
			state.getSelectedCategoryListCompleted = APIStatus.FULFILLED;
			state.categorySelectedListLoading = false;
			state.categorySelectedList = action.payload.data;
		});
		builder.addCase(getSelectedCategoryList.rejected, (state) => {
			state.getSelectedCategoryListCompleted = APIStatus.REJECTED;
			state.categorySelectedListLoading = false;
		});
	},
});
export const {
	clearGetAllCuisineList,
	clearGetAllCategoryList,
	clearGetAllSelectedCuisineList,
	clearGetAllSelectedCategoryList,
} = advanceAnalysisSlice.actions;

export default advanceAnalysisSlice.reducer;
