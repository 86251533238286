import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { getAllBrandDetailsByType, getAllPopularKeysDetails } from "./homepage.service";
import { HomePageReducer } from "../../modal/home-page";
import brandDetailsByTypeData  from "../../constants/homepage/brand-details-by-type.json";
import popularKeysData from "../../constants/homepage/popular-keys.json";

const initialState: HomePageReducer = {
	getAllBrandDetailsByTypeComplete: null,
	getAllPopularKeysComplete: null,
	brandDetails: [],
	popularKeys: [],
};

const homePageSlice = createSlice({
	name: "homepage",
	initialState,
	reducers: {
		clearGetAllBrandDetailsByType(state) {
			state.getAllPopularKeysComplete = null;
		},
		clearGetAllPopularKeys(state) {
			state.getAllPopularKeysComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getAllBrandDetailsByType.fulfilled, (state, action) => {
			state.getAllBrandDetailsByTypeComplete = APIStatus.FULFILLED;
			state.brandDetails = action.payload.data;
		});
		builder.addCase(getAllBrandDetailsByType.rejected, (state) => {
			state.getAllBrandDetailsByTypeComplete = APIStatus.REJECTED;
			//will remove after api integration
			state.brandDetails = brandDetailsByTypeData;
		});
		builder.addCase(getAllPopularKeysDetails.fulfilled, (state, action) => {
			state.getAllPopularKeysComplete = APIStatus.FULFILLED;
			state.popularKeys = action.payload.data;
		});
		builder.addCase(getAllPopularKeysDetails.rejected, (state) => {
			state.getAllPopularKeysComplete = APIStatus.REJECTED;
			//will remove after api integration
			state.popularKeys = popularKeysData;
		});
	},
});

export const { clearGetAllBrandDetailsByType, clearGetAllPopularKeys } = homePageSlice.actions;

export default homePageSlice.reducer;
