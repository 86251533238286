import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FranchiseSelectForm from "../../components/franchise-select-form";
import { LocalStorageKeys } from "../../constants";
import { FranchiseSelectHandles } from "../../modal/franchise-select";
import AnalyzeLocationModal from "../analyze-location-modal";
import "./franchise-positioning.scss";
import { DarkHeader } from "../../components/dark-header";
import { Config } from "../../config";
import { AnalyzeLocationHandles } from "../../modal/analyze-location";
import { PlanTypes } from "../../constants/franchise-select/planTypes";
import { useAppDispatch } from "../../modal/hooks";
import { saveBrands } from "../../services/brand-select/brand-select.slice";

export const FranchisePositioning: React.FunctionComponent = () => {
	const franchiseSelectFormRef = useRef<FranchiseSelectHandles>(null);
	const navigate = useNavigate();
	const analyzeLocationFormRef = useRef<AnalyzeLocationHandles>(null);
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	useEffect(() => {
		if (localStorage.getItem(LocalStorageKeys.FRANCHISE_POSITIONING)) {
			localStorage.removeItem(LocalStorageKeys.FRANCHISE_POSITIONING);
		}
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("planType", params.get("brandExists") === "false" ? PlanTypes.CLOUD_STORE : PlanTypes.MICRO_STORE);
		const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
		navigate(newUrl);
		dispatch(saveBrands({ brandList: [], persist: false }));
	}, []);

	const handleClick = () => {
		if (franchiseSelectFormRef.current && franchiseSelectFormRef.current.isValid()) {
			localStorage.setItem(
				LocalStorageKeys.FRANCHISE_POSITIONING,
				JSON.stringify({ ...franchiseSelectFormRef.current.getValue() })
			);
			fetchLatLongUsingAddress();
			return true;
		} else {
			return false;
		}
	};

	const fetchLatLongUsingAddress = () => {
		const state: string = franchiseSelectFormRef.current?.getValue().state ?? "";
		const city: string = franchiseSelectFormRef.current?.getValue().cities ?? "";
		const address: string = franchiseSelectFormRef.current?.getValue().address ?? "";
		const locationAddress: string[] = [address, state, city];
		fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${locationAddress.join(", ")}&key=${
				Config.apiKeyClient
			}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.status === "OK") {
					addQueryParams(data.results[0].geometry.location);
				} else {
					analyzeLocationFormRef.current?.closeModal();
					console.error("Geocoding failed:", data.status);
				}
			})
			.catch((error) => {
				analyzeLocationFormRef.current?.closeModal();
				console.error("Error:", error);
			});
	};

	const addQueryParams = (location: google.maps.LatLngLiteral) => {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set("location", JSON.stringify(location));
		const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
		navigate(newUrl);
	};

	return (
		<div className="main_container w-100">
			<DarkHeader
				subHeader={[
					<div key={"develop_brand_sub_header_items"} className="container">
						<div className="margin_x_neg_5 d-flex align-items-center justify-content-center flex-wrap">
							<div className="custom_pill flex_1_1_10 custom_pill_filled">1 加盟定位</div>
							<div className="custom_pill">2 制定品牌</div>
							<div className="custom_pill">3 預測結果</div>
						</div>
					</div>,
				]}
			/>
			<div className="content_box">
				<div className="franchise_content_container">
					<FranchiseSelectForm ref={franchiseSelectFormRef} />
					<AnalyzeLocationModal handleClick={handleClick} ref={analyzeLocationFormRef} />
				</div>
			</div>
		</div>
	);
};
