import React from "react";
import { DeliveryPlatform } from "../../constants/analyze-location/delivery-platform";
import { useAppSelector } from "../../modal/hooks";
import "./meals-on-platform.scss";

type MealsOnEachPlatformProps = {
	setMapType: (el: string) => void;
	googleMapTotalCount: number;
	mapTypeVariable: string;
};

export const MealsOnEachPlatform: React.FunctionComponent<MealsOnEachPlatformProps> = (props) => {
	const shopCount = useAppSelector((store) => store.analyzeLocation.shopCount);
	return (
		<div className="platform_content_box">
			<div className="content_box_sub_title">各平台餐飲數量</div>
			<div className="mt-2">
				<div className="d-flex align-items-center justify-content-between cursor-pointer">
					<div
						className={`${
							props.mapTypeVariable === DeliveryPlatform.ALL ? "platform_custom_pill_active" : ""
						} custom_pill platform_custom_pill py-2`}
						onClick={() => props.setMapType(DeliveryPlatform.ALL)}
					>
						全部
					</div>
					<div className="font_weight_medium pl-1 ellipsis">
						{props.googleMapTotalCount + shopCount.uberEatsCount + shopCount.fpShopCount}間店家
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between cursor-pointer">
					<div
						className={`${
							props.mapTypeVariable === DeliveryPlatform.GOOGLE_MAPS ? "platform_custom_pill_active" : ""
						} custom_pill platform_custom_pill py-2`}
						onClick={() => props.setMapType(DeliveryPlatform.GOOGLE_MAPS)}
					>
						<img src="/images/google-maps.png" />
					</div>
					<div className="font_weight_medium pl-1 ellipsis">{props.googleMapTotalCount}間店家</div>
				</div>
				<div className="d-flex align-items-center justify-content-between cursor-pointer">
					<div
						className={`${
							props.mapTypeVariable === DeliveryPlatform.UBER_EATS ? "platform_custom_pill_active" : ""
						} custom_pill platform_custom_pill py-2`}
						onClick={() => props.setMapType(DeliveryPlatform.UBER_EATS)}
					>
						<img src="/images/uber-eats.png" />
					</div>
					<div className="font_weight_medium pl-1 ellipsis">{shopCount.uberEatsCount}間店家</div>
				</div>
				<div className="d-flex align-items-center justify-content-between cursor-pointer">
					<div
						className={`${
							props.mapTypeVariable === DeliveryPlatform.FOOD_PANDA ? "platform_custom_pill_active" : ""
						} custom_pill platform_custom_pill py-2`}
						onClick={() => props.setMapType(DeliveryPlatform.FOOD_PANDA)}
					>
						<img className="food_panda_img" src="/images/food-panda.png" alt="img" />
					</div>
					<div className="font_weight_medium pl-1 ellipsis">{shopCount.fpShopCount}間店家</div>
				</div>
			</div>
		</div>
	);
};
