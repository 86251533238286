import { createSlice } from "@reduxjs/toolkit";
import { getBrandDetails, getKeyWords, getMenu } from "./brand-details.service";
import { APIStatus } from "../../constants";
import { BrandDetails, KeyWordData, MenuData } from "../../modal/brand-details";
export interface BrandDetailsReducer {
	getBrandDetailsComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	brandDetails: BrandDetails;
	getKeyWordsComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	brandDetailsKeyWords: KeyWordData[];
	getMenuComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	brandDetailsMenu: MenuData[];
	brandDetailsLoading: boolean;
}

const initialState: BrandDetailsReducer = {
	getBrandDetailsComplete: null,
	brandDetails: {
		id: 0,
		name: "",
		headerImage: "",
		logoImage: "",
		menuImage: "",
		brandWebsiteLink: "",
		brandStrongholdLink: "",
		equipmentMaterialsLink: "",
		description: "",
		rating: "",
		brandClass: "",
		type: "",
	},
	getKeyWordsComplete: null,
	brandDetailsKeyWords: [],
	getMenuComplete: null,
	brandDetailsMenu: [],
	brandDetailsLoading: false,
};

const brandDetailsSlice = createSlice({
	name: "brandDetails",
	initialState,
	reducers: {
		clearGetBrandDetails(state) {
			state.getBrandDetailsComplete = null;
		},
		clearBrandDetailsData(state) {
			state.brandDetails = {} as BrandDetails;
		},
		clearGetKeyWords(state) {
			state.getKeyWordsComplete = null;
		},
		clearKeywordsData(state) {
			state.brandDetailsKeyWords = [];
		},
		clearGetMenu(state) {
			state.getMenuComplete = null;
		},
		clearMenuData(state) {
			state.brandDetailsMenu = [];
		},
	},
	extraReducers(builder) {
		builder.addCase(getBrandDetails.pending, (state) => {
			state.brandDetailsLoading = true;
		});
		builder.addCase(getBrandDetails.fulfilled, (state, action) => {
			state.getKeyWordsComplete = APIStatus.FULFILLED;
			state.brandDetails = action.payload.data;
			state.brandDetailsLoading = false;
		});
		builder.addCase(getBrandDetails.rejected, (state) => {
			state.getKeyWordsComplete = APIStatus.REJECTED;
			state.brandDetailsLoading = false;
		});
		builder.addCase(getKeyWords.fulfilled, (state, action) => {
			state.getKeyWordsComplete = APIStatus.FULFILLED;
			state.brandDetailsKeyWords = action.payload.data;
		});
		builder.addCase(getKeyWords.rejected, (state) => {
			state.getKeyWordsComplete = APIStatus.REJECTED;
		});
		builder.addCase(getMenu.fulfilled, (state, action) => {
			state.getMenuComplete = APIStatus.FULFILLED;
			state.brandDetailsMenu = action.payload.data.categories;
		});
		builder.addCase(getMenu.rejected, (state) => {
			state.getMenuComplete = APIStatus.REJECTED;
		});
	},
});

export const { clearGetBrandDetails,clearBrandDetailsData, clearGetKeyWords, clearKeywordsData, clearGetMenu, clearMenuData } = brandDetailsSlice.actions;

export default brandDetailsSlice.reducer;
