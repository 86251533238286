import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";

export const getAllBrandDetailsByType = createAsyncThunk("homepage/details", async (type: string) => {
	const response = await axios.get(Config.apiUrl + `/brand/summary/${type}`, axiosConfig());
	return response.data;
});

export const getAllPopularKeysDetails = createAsyncThunk("homepage/keys", async () => {
	const response = await axios.get(Config.apiUrl + "/brand/summary/keywords", axiosConfig());
	return response.data;
});
