import React, { useState } from "react";
import { OnBoardingPage } from "../../containers/onBoardingpage";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import "./home-page-content.scss";

export const HomepageContent: React.FunctionComponent = () => {
	const [isOnBoardingModalOpen, setIsOnboardingModalOpen] = useState(false);
	const navigate = useNavigate();

	return (
		<div className="home_page_content_container">
			<div className="container">
				<header className="home_page_header">
					<div className="d-flex align-items-center justify-content-between">
						<div className="home_logo">
							<img src="/images/logo.png" alt="logo" />
						</div>
						<div className="login-details">
							<button className="primary_border_btn home_btn mr-2" onClick={() => setIsOnboardingModalOpen(true)}>
								加入我們
							</button>
							<button className="primary_border_btn home_btn ml-1" onClick={() => navigate(Locations.LOGIN)}>
								登入/註冊
							</button>
						</div>
					</div>
				</header>
			</div>
			<section className="banner_image home_banner home_outer_bg h-100">
				<div className="corner_text">
					<div className="outer_content_box" onClick={() => setIsOnboardingModalOpen(true)}>
						<div className="cursor-pointer pr-2">Discover Your Perfect</div>
						<div className="cursor-pointer">Franchise Opportunity</div>
					</div>
				</div>
			</section>
			<>{isOnBoardingModalOpen ? <OnBoardingPage setIsOnBoardingModalOpen={setIsOnboardingModalOpen} /> : ""}</>
		</div>
	);
};
